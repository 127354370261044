
import { defineComponent, computed } from "vue";
import { routeName } from "@/config/router";
import useCategories from "@/composibles/use-categories";
import useApp from "@/composibles/use-app";
import { useRoute } from "vue-router";

export const name = "context";
export const component = defineComponent({
  name,
  setup() {
    const $route = useRoute();
    const { context, Context } = useApp();

    return {
      contextTitle: computed(() => {
        if (context.value == Context.Favorites) {
          return Context[context.value];
        } else {
          const { getCategoryTitle, activeCategoryId } = useCategories();

          return getCategoryTitle(activeCategoryId.value);
        }
      }),
      showContextTitle: computed(() => routeName.app == $route.name && context.value !== Context.Default),
    };
  },
});

export default component;
