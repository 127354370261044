import { color } from "./tailwind";
import { CategoryId } from "./_app-page/_categories";
import { RouteLocationRaw } from "vue-router";
import { PoseId } from "@/config/_app-page/_pose-id";
import { Config, ConfigTexts } from "@/types/config";
import { Poses } from "./_app-page/_poses";
import { Categories } from "./_app-page/_categories";

interface AppConfig extends Config {
  texts: AppConfigTexts;
  Poses: Pose[];
  Categories: Category[];
}

interface AppConfigTexts extends ConfigTexts {
  title: string;
  sharePose: {
    title: string;
    text: string;
  };
}

const texts: AppConfigTexts = {
  title: "Play",
  sharePose: {
    title: "Clicy - Sex Related Resources Library",
    text: "Treat Clicy as a sex related library and use it to expand your sexual horizons and have fun experimenting!",
  },
};

export interface Category {
  id: CategoryId;
  title: string;
  thumbnailImageName: string;
  // thumbnail: () => string | null;
  toCategoryPosesListRoute: RouteLocationRaw;
}

export interface Pose {
  id: PoseId;
  hash: string;
  title: string;
  description: string;
  imageName: string;
  // imageContent: () => string | null;
  backgroundColor: color;
  categoriesIds: CategoryId[];
}

const config: AppConfig = {
  Poses,
  Categories,
  texts,
};

export default config;
