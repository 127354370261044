import { color } from "../tailwind";
import { getPoseAssetAsBase64 } from "@/utils/assets";
import hash from "object-hash";
import { Pose } from "@/config/app-page";
import { PoseId } from "./_pose-id";
import { CategoryId } from "./_categories";

export const Poses: Pose[] = [
  {
    id: PoseId.magicCarpet,
    title: `Magic Carpet`,
    description: ``,
    imageName: "62054_magic_carpet.svg",
    backgroundColor: color.blue400,
    categoriesIds: [CategoryId.RearEntry, CategoryId.LyingDown],
  },

  {
    id: PoseId.lyingDown,
    title: `Lying Down`,
    description: ``,
    imageName: "62064_lying_down.svg",
    backgroundColor: color.red300,
    categoriesIds: [CategoryId.LyingDown, CategoryId.RearEntry],
  },
  {
    id: PoseId.rider,
    title: `Rider`,
    description: ``,
    imageName: "62067_rider.svg",
    backgroundColor: color.red300,
    categoriesIds: [CategoryId.SheOnTop, CategoryId.FaceToFace],
  },
  {
    id: PoseId.cowgirl,
    title: `Cowgirl`,
    description: ``,
    imageName: "62083_cowgirl.svg",
    backgroundColor: color.gray300,
    categoriesIds: [CategoryId.SheOnTop, CategoryId.FaceToFace],
  },
  {
    id: PoseId.arch,
    title: `Arch`,
    description: `He sits with his legs straight and you sit on top of him with bent knees on top of his thighs, and you both lean back. She could try to wrap her legs around his back for additional thrust.`,
    imageName: "62038_arch.svg",
    backgroundColor: color.gray300,
    categoriesIds: [CategoryId.SheOnTop, CategoryId.FaceToFace],
  },
  {
    id: PoseId.gemini,
    title: `Gemini`,
    description: ``,
    imageName: "62050_gemini.svg",
    backgroundColor: color.gray300,
    categoriesIds: [CategoryId.FaceToFace],
  },
  {
    id: PoseId.spoon,
    title: `Spoon`,
    description: ``,
    imageName: "62065_spoon.svg",
    backgroundColor: color.gray300,
    categoriesIds: [CategoryId.RearEntry, CategoryId.Anal],
  },
  {
    id: PoseId.concubine,
    title: `Concubine`,
    description: `He kneels as she stands in front of him, facing away, with her feet side to side near his knees. She slowly lowers herself to the waist from where he should enter and once he is inside her she can lower herself down until she is lying on her belly. She then wraps her legs around his back while he holds her hips and thrusts deeper. From this position, she can rest her weight on her elbows or her chest with her arms reaching out in front. Avoid resting the weight on the shoulders or near the neck as it might lead to an injury. This position offers deeper penetration and allows to introduce some additional anal play. G-spot and P-spot stimulation are more intense in this position.`,
    imageName: "62043_concubine.svg",
    backgroundColor: color.blue400,
    categoriesIds: [CategoryId.Anal, CategoryId.RearEntry],
  },
  {
    id: PoseId.italianChandelier,
    title: `Italian Chandelier`,
    description: `He lies on his back with his legs spread. She stands over him with her feet at either side of his thighs, facing away from him. She slowly lowers herself down and guides herself onto him until he is fully inside of her. From this position, she bends backward and supports her weight on her hands on either side of him. Then she places her feet on his thighs and starts riding. This position allows for an additional clitoris play and increased sensitivity for A-spot and G-spot stimulation as well as easy access to her breasts.`,
    imageName: "62046_italian_chandelier.svg",
    backgroundColor: color.blue400,
    categoriesIds: [CategoryId.SheOnTop, CategoryId.LyingDown],
  },

  {
    id: PoseId.boat,
    title: `Boat`,
    description: ``,
    imageName: "62061_boat.svg",
    backgroundColor: color.red300,
    categoriesIds: [CategoryId.LyingDown, CategoryId.Anal],
  },
  {
    id: PoseId.swing,
    title: `Swing`,
    description: ``,
    imageName: "62066_swing.svg",
    backgroundColor: color.blue400,
    categoriesIds: [CategoryId.SheOnTop, CategoryId.RearEntry],
  },
  {
    id: PoseId.reverseCowgirl,
    title: `Reverse Cowgirl`,
    description: `He lies on his back she faces his feet while putting her feet under him slightly bends and sits on him. For additional stability and force, she can place her hands on his knees to match the rhythm.`,
    imageName: "62037_reverse_cowgirl.svg",
    backgroundColor: color.red300,
    categoriesIds: [CategoryId.RearEntry, CategoryId.SheOnTop],
  },
  {
    id: PoseId.piledriver,
    title: `Piledriver`,
    description: ``,
    imageName: "62077_piledriver.svg",
    backgroundColor: color.gray300,
    categoriesIds: [CategoryId.Anal, CategoryId.FaceToFace],
  },
].map((pose) => ({
  ...pose,
  hash: hash(pose.id),
  // imageContent: () => getPoseAssetAsBase64(pose.imageName),
}));
