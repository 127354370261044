import { ref, computed, watch } from "vue";

const isLoadingCircle = ref(true);
const loadingEl = document.getElementById("loading");

// Watchers
watch(isLoadingCircle, (newState) => {
  if (loadingEl) loadingEl.hidden = !newState;
});

// Modifiers
function stopLoadingCircle() {
  isLoadingCircle.value = false;
}

export default function () {
  return {
    // Sates
    isLoadingCircle: computed(() => isLoadingCircle.value),

    // Modifiers
    stopLoadingCircle,
  };
}
