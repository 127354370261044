
import usePoses from "@/composibles/use-poses";
import { color } from "@/config/tailwind";
import { PoseId } from "@/config/_app-page/_pose-id";
import { defineComponent, PropType, ref } from "vue";
import { StripePriceId } from "@/types/_modules/get-premium";
import { redirectToCheckout } from "@/services/stripe";
import { getPoseAssetAsBase64 } from "@/utils/assets";

export const name = "sub-premium-card-component";

export const component = defineComponent({
  name,
  props: {
    title: {
      type: String,
      required: true,
    },
    subscriptionPlanName: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    priceForMonthInCents: {
      type: Number,
      required: true,
    },
    poseId: {
      type: String as PropType<PoseId>,
      required: true,
    },
    colorEnum: {
      type: String as PropType<color>,
      required: true,
    },
    submitButtonText: {
      type: String,
      required: true,
    },
    priceId: {
      type: String as PropType<StripePriceId>,
      required: true,
    },
  },
  setup(props) {
    const loading = ref(false);
    const { getPoseById } = usePoses();

    const pose = getPoseById(props.poseId);

    if (!pose) {
      // eslint-disable-next-line no-console
      console.error(`Pose not found ${props.poseId}`);
    }

    return {
      getPoseAssetAsBase64,
      // Vars
      poseSrcName: pose.imageName,
      loading,

      // Methods
      formatPriceToString(priceInCents: number) {
        return (priceInCents / 100).toFixed(2);
      },
      async submit() {
        try {
          loading.value = true;

          await redirectToCheckout(props.priceId);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      },
    };
  },
});

export default component;
