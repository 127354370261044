import { RouteRecordRaw } from "vue-router";
import { routeName } from "@/config/router";
import ContactUsConfig from "@/config/contact-us-page";

// Components
import ContactUsView from "./views/contact-us-view.vue";
import MainLayout from "@/layouts/main-layout/main-layout.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/contact-us",
    component: MainLayout,
    children: [
      {
        name: routeName.contactUs,
        path: "",
        component: ContactUsView,
        meta: {
          pageTitle: ContactUsConfig.texts.title,
          isValidForUser: true,
        },
      },
    ],
  },
];

export default routes;
