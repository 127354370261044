
import { defineComponent, onMounted } from "vue";
import LegalConfig from "@/config/legal-page";
import useBackground from "@/composibles/use-background";
import { getMainAssetAsBase64 } from "@/utils/assets";
import useTailwind from "@/composibles/use-tailwind";
import { color } from "@/config/tailwind";

export const name = "legal-view";

export const component = defineComponent({
  name,
  setup() {
    const { isMobile } = useTailwind();

    onMounted(() => {
      useBackground().changeBgColorClass(color.white);
    });

    const circle1Style = `background-image: url('${getMainAssetAsBase64("legal/circle-1.svg")}')`;
    const circle2Style = `background-image: url('${getMainAssetAsBase64("legal/circle-2.svg")}')`;
    const circle3Style = `background-image: url('${getMainAssetAsBase64("legal/circle-3.svg")}')`;
    const circle4Style = `background-image: url('${getMainAssetAsBase64("legal/circle-4.svg")}')`;

    return {
      texts: LegalConfig.texts,
      isMobile,

      circle1Style,
      circle2Style,
      circle3Style,
      circle4Style,
    };
  },
});

export default component;
