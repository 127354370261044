
import { defineComponent } from "vue";

export const name = "vs-icon-favorite-outline";

export const component = defineComponent({
  name,
});

export default component;
