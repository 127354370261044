import { createRouter, createWebHistory } from "vue-router";
import { Routes as ModulesRoutes } from "@/modules/index";
import { routeName } from "@/config/router";
import usePoseShare from "./composibles/use-pose-share";
import useApp from "./composibles/use-app";

const sharePoseRoute = usePoseShare().getPoseShareRoute();
const anyPageRoute = { path: "/:pathMatch(.*)*", redirect: { name: routeName.landing } };

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [sharePoseRoute, ...ModulesRoutes, anyPageRoute],
});

router.beforeEach(async (to, from, next) => {
  if (to.name === routeName.appShortCut) {
    const { setActivePoseId, decodePoseSharedLinkToPoseId, saveSharedPoseId } = useApp();

    // This will change to active pose id
    const poseId = decodePoseSharedLinkToPoseId(to.params.id as string);

    if (poseId) {
      setActivePoseId(poseId);
      saveSharedPoseId(poseId);
    }
  }

  try {
    if (typeof to.meta.isValidForUser !== "boolean" && to.meta.isValidForUser) {
      const fallbackItem = to.meta.isValidForUser.find((item) => item.valid.value === false);

      // Fallback user if validation failed
      if (fallbackItem) return next({ name: fallbackItem.fallbackUrl });

      // Redirect user if valid
      if (to.meta.redirectToOnValid) return next({ name: to.meta.redirectToOnValid });
    }

    // User can reach this route
    return next();
  } catch (e) {
    return next({ name: routeName.landing });
  }
});

export default router;
