
import { defineComponent } from "vue";
import usePremium from "@/composibles/use-premium";
import MySubscriptionConfig from "@/config/my-subscription-page";

export const name = "invite-partner-form";

export const component = defineComponent({
  name,
  setup() {
    const {
      partnerEmail,
      isInviteEmailSent,
      invitePartnerSubmit,
      isInviteFormDisabled,
      invitePartnerFormError,
    } = usePremium();

    return {
      partnerEmail,
      isInviteEmailSent,
      invitePartnerSubmit,
      isInviteFormDisabled,
      invitePartnerFormError,
      isInviteEmailSentText: MySubscriptionConfig.texts.isInviteEmailSent,
      invitePartnerSubmitButtonText: MySubscriptionConfig.texts.invitePartnerSubmitButtonText,
      invitePartnerEmailInputPlaceholder: MySubscriptionConfig.texts.invitePartnerEmailInputPlaceholder,
    };
  },
});

export default component;
