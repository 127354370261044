
import { defineComponent } from "vue";
import usePremium from "@/composibles/use-premium";
import MySubscriptionConfig from "@/config/my-subscription-page";
import useAuth from "@/composibles/use-auth";

export const name = "revoke-partner-access-form";

export const component = defineComponent({
  name,
  setup() {
    const { invitedPartnerEmail } = useAuth();
    const { revokeAccessForInvitedPartner, revokeAccessButtonLoading } = usePremium();

    return {
      invitedPartnerEmail,
      revokeAccessButtonLoading,
      revokeAccessText: MySubscriptionConfig.texts.revokeAccessText,
      invitedPartnerEmailPretext: MySubscriptionConfig.texts.invitedPartnerEmailPretext,
      revokeAccessForInvitedPartner,
    };
  },
});

export default component;
