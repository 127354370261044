import { RouteRecordRaw } from "vue-router";
import { routeName } from "@/config/router";
import LegalConfig from "@/config/legal-page";

// Components
import LegalView from "./views/legal-view.vue";
import MainLayout from "@/layouts/main-layout/main-layout.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/legal",
    component: MainLayout,
    children: [
      {
        name: routeName.legal,
        path: "",
        component: LegalView,
        meta: {
          pageTitle: LegalConfig.texts.title,
          isValidForUser: true,
        },
      },
    ],
  },
];

export default routes;
