export enum PoseId {
  upstandingCitizen = "62036",
  reverseCowgirl = "62037",
  arch = "62038",
  pretzelDip = "62039",
  eiffelTower = "62040",
  veledictorian = "62041",
  eagle = "62042",
  concubine = "62043",
  spreadEagle = "62044",
  casket = "62045",
  italianChandelier = "62046",
  classic69 = "62047",
  inverted69 = "62048",
  reverseCowboy = "62049",
  gemini = "62050",
  merger = "62051",
  balletDancer = "62052",
  seatedWheelbarrow = "62053",
  magicCarpet = "62054",
  leopard = "62055",
  amazon = "62056",
  kneel = "62057",
  dolphin = "62058",
  necking = "62059",
  screwdriverSexPosition = "62060",
  boat = "62061",
  butterChurner = "62062",
  spider = "62063",
  lyingDown = "62064",
  spoon = "62065",
  swing = "62066",
  rider = "62067",
  pole = "62068",
  armlock = "62069",
  theHero = "62070",
  turtle = "62071",
  narcissus = "62072",
  starfish = "62073",
  rainbow = "62074",
  rabbitEars = "62075",
  wheelBarrow = "62076",
  piledriver = "62077",
  bloomingOrchid = "62078",
  lotus = "62079",
  highwayToHeaven = "62080",
  lapDance = "62081",
  throne = "62082",
  cowgirl = "62083",
  antiGravityBall = "62084",
  wildPony = "62085",
  sevenAndOne = "62086",
  handWashCycle = "62087",
  footLocker = "62088",
  cliffHanger = "62089",
  sidecar = "62090",
  midfieldSack = "62091",
  wheelbarrow2 = "62092",
  superman = "62093",
  mermaid = "62094",
  waterfall = "62095",
  fireHydrant = "62096",
  happyHands = "62097",
}
