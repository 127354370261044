/**
 * Available routes names
 * * Usage example $router.push({ name: routeName.legal })
 */
export enum routeName {
  landing = "landing",
  legal = "legal",
  app = "app",
  appShortCut = "app-short-cut",
  categories = "categories",
  favorites = "favorites",
  categoryPosesList = "category-poses-list",
  contactUs = "contact-us",
  about = "about",
  getPremium = "get-premium",
  successSubscription = "success-subscription",
  mySubscription = "my-subscription",

  // Login
  loginWithEmailLink = "loginWithEmailLink",
  loginWithEmailLinkConfirm = "loginWithEmailLinkConfirm",
}
