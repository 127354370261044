
import { defineComponent } from "vue";
import useListLayout from "@/composibles/use-list-layout";
import TopBar from "../_components/top-bar.vue";

export const name = "list-layout";
export const component = defineComponent({
  name,
  components: { TopBar },
  setup() {
    const { breadcrumbs } = useListLayout();

    return { breadcrumbs };
  },
});

export default component;
