
import { defineComponent } from "vue";

export const name = "vs-icon-hamburger";

export const component = defineComponent({
  name,
  props: {
    smallShadow: {
      type: Boolean,
      required: false,
      default: false,
    },
    bigShadow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});

export default component;
