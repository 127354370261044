import { RouteRecordRaw } from "vue-router";

// Configs
import { routeName } from "@/config/router";

// Components
import LandingView from "@/modules/landing/views/landing-view.vue";
import LandingLayout from "@/layouts/landing-layout/landing-layout.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: LandingLayout,
    children: [
      {
        path: "",
        name: routeName.landing,
        component: LandingView,
        meta: {
          isValidForUser: true,
        },
      },
    ],
  },
];

export default routes;
