export interface PointerEventConfig {
  pointerStartCb: EventListenerOrEventListenerObject;
  pointerMoveCb: EventListenerOrEventListenerObject;
  pointerUpCb: EventListenerOrEventListenerObject;
  pointerCancelCb: EventListenerOrEventListenerObject;
}

export function pointerEventHandler(
  el: HTMLElement,
  pointerEventConfig: PointerEventConfig,
  options: EventListenerOptions
) {
  if (!el) {
    throw new Error("Element id not defined");
  }

  if (window.PointerEvent) {
    el.addEventListener("pointerdown", pointerEventConfig.pointerStartCb, options);
    el.addEventListener("pointermove", pointerEventConfig.pointerMoveCb, options);
    el.addEventListener("pointerup", pointerEventConfig.pointerUpCb, options);
    el.addEventListener("pointercancel", pointerEventConfig.pointerCancelCb, options);
  } else {
    el.addEventListener("touchstart", pointerEventConfig.pointerStartCb, options);
    el.addEventListener("touchmove", pointerEventConfig.pointerMoveCb, options);
    el.addEventListener("touchend", pointerEventConfig.pointerUpCb, options);
    el.addEventListener("touchcancel", pointerEventConfig.pointerCancelCb, options);

    // Add mouse event
    el.addEventListener("mousedown", pointerEventConfig.pointerStartCb);
  }
}

export function removeEventHandler(
  el: HTMLElement,
  pointerEventConfig: PointerEventConfig,
  options: EventListenerOptions
) {
  if (!el) {
    throw new Error("Element id not defined");
  }

  if (window.PointerEvent) {
    el.removeEventListener("pointerdown", pointerEventConfig.pointerStartCb, options);
    el.removeEventListener("pointermove", pointerEventConfig.pointerMoveCb, options);
    el.removeEventListener("pointerup", pointerEventConfig.pointerUpCb, options);
    el.removeEventListener("pointercancel", pointerEventConfig.pointerCancelCb, options);
  } else {
    el.removeEventListener("touchstart", pointerEventConfig.pointerStartCb, options);
    el.removeEventListener("touchmove", pointerEventConfig.pointerMoveCb, options);
    el.removeEventListener("touchend", pointerEventConfig.pointerUpCb, options);
    el.removeEventListener("touchcancel", pointerEventConfig.pointerCancelCb, options);

    // Add mouse event
    el.removeEventListener("mousedown", pointerEventConfig.pointerStartCb);
  }
}
