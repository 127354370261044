import { RouteRecordRaw } from "vue-router";
import { routeName } from "@/config/router";
import AboutUsConfig from "@/config/about-us-page";

// Components
import AboutView from "./views/about-us-view.vue";
import MainLayout from "@/layouts/main-layout/main-layout.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/about",
    component: MainLayout,
    children: [
      {
        name: routeName.about,
        path: "",
        component: AboutView,
        meta: { pageTitle: AboutUsConfig.texts.title, isValidForUser: true },
      },
    ],
  },
];

export default routes;
