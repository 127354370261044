import { ref, computed, watch } from "vue";
import { isEmailValid } from "@/utils/validator";
import { sendEmailWithLoginLink, confirmLoginEmailLink, onUserStateChange, logoutUser } from "@/services/firebase";
import { generate as getRandomString } from "randomstring";
import LoginConfig from "@/config/login-page";

const LS_LOGIN_EMAIL = "LOGIN_EMAIL";

interface CallbacksList {
  [index: string]: () => Promise<void> | void;
}

const _loginError = ref("");
const email = ref(localStorage.getItem(LS_LOGIN_EMAIL) || "");
const _isLoading = ref(false);
const _isEmailSent = ref(false);
const _isUserLoggedIn = ref(false);
const _isEmailValid = ref(isEmailValid(email.value));
const _onLoginCbs = {} as CallbacksList;
const _onLogoutCbs = {} as CallbacksList;

function _emailSent() {
  _isEmailSent.value = true;
}

function _emailNotSent() {
  _isEmailSent.value = false;
}

function _loading() {
  _isLoading.value = true;
}

function _notLoading() {
  _isLoading.value = false;
}

function _saveEmailToLS() {
  localStorage.setItem(LS_LOGIN_EMAIL, email.value);
}

function _removeEmailFromLS() {
  localStorage.removeItem(LS_LOGIN_EMAIL);
  email.value = "";
}

function _setLoginError(msg: string) {
  _loginError.value = msg;
}

function _removeLoginError() {
  _loginError.value = "";
}

function loginSubmit(e: Event) {
  e.preventDefault();

  if (_isEmailValid.value) {
    _loading();

    sendEmailWithLoginLink(email.value).then(_emailSent).then(_saveEmailToLS).then(_notLoading);
  }
}

function confirmLogin() {
  if (!email.value) {
    _setLoginError(LoginConfig.texts.error.noEmilForLogin);

    throw new Error(_loginError.value);
  }

  return confirmLoginEmailLink(email.value, location.href).then(_removeEmailFromLS);
}

function _emailInputChanged(newEmail: string) {
  _isEmailValid.value = isEmailValid(newEmail);
  _emailNotSent();

  if (newEmail.length > 0 && _loginError.value) {
    _removeLoginError();
  }
}

async function _userLoggedIn() {
  _isUserLoggedIn.value = true;

  for (const fn of Object.entries(_onLoginCbs).map((item) => item[1])) await fn();
}

async function _userLoggedOut() {
  _isUserLoggedIn.value = false;

  for (const fn of Object.entries(_onLogoutCbs).map((item) => item[1])) await fn();
}

function logout() {
  return logoutUser();
}

async function registerUserStateChangeHandlers() {
  return onUserStateChange(_userLoggedIn, _userLoggedOut);
}

function onLogin(cb: () => Promise<void> | void): string {
  const uIndex = getRandomString(10);

  if (_onLoginCbs[uIndex]) {
    return onLogin(cb);
  }

  _onLoginCbs[uIndex] = cb;

  return uIndex;
}

function onLogout(cb: () => Promise<void> | void): string {
  const uIndex = getRandomString(10);

  if (_onLogoutCbs[uIndex]) {
    return onLogout(cb);
  }

  _onLogoutCbs[uIndex] = cb;

  return uIndex;
}

export default function () {
  // Watchers
  watch(email, _emailInputChanged);

  return {
    // Actions
    loginSubmit,
    confirmLogin,
    logout,
    registerUserStateChangeHandlers,

    // On status change
    onLogin,
    onLogout,

    // mutable values
    email,

    // states
    loginError: computed(() => _loginError.value),
    isEmailSent: computed(() => _isEmailSent.value),
    isEmailValid: computed(() => _isEmailValid.value),
    isUserLoggedIn: computed(() => _isUserLoggedIn.value),
    isSubmitDisabled: computed(() => _isEmailSent.value || !_isEmailValid.value || _isLoading.value),
  };
}
