import { App } from "vue";

// VsButton
import * as VsButton from "./vs-button/vs-button.vue";

// VsCard
import * as VsCard from "./vs-cards/vs-card.vue";

// VsIcons
import * as VsLoading from "./vs-icons/vs-loading.vue";
import * as VsLogin from "./vs-icons/vs-icon-login.vue";
import * as VsIconLock from "./vs-icons/vs-icon-lock.vue";
import * as VsIconClose from "./vs-icons/vs-icon-close.vue";
import * as VsIconShare from "./vs-icons/vs-icon-share.vue";
import * as VsIconPhone from "./vs-icons/vs-icon-phone.vue";
import * as VsIconEmail from "./vs-icons/vs-icon-email.vue";
import * as VsIconHamburger from "./vs-icons/vs-icon-hamburger.vue";
import * as VsIconChevronLeft from "./vs-icons/vs-icon-chevron-left.vue";
import * as VsIconChevronRight from "./vs-icons/vs-icon-chevron-right.vue";
import * as VsIconFavoriteFilled from "./vs-icons/vs-icon-favorite-filled.vue";
import * as VsIconFavoriteOutline from "./vs-icons/vs-icon-favorite-outline.vue";

export function addGlobalComponents(app: App) {
  // vs-button
  app.component(VsButton.name, VsButton.component);

  // vs-card
  app.component(VsCard.name, VsCard.component);

  // Icons
  app.component(VsLogin.name, VsLogin.component);
  app.component(VsLoading.name, VsLoading.component);
  app.component(VsIconLock.name, VsIconLock.component);
  app.component(VsIconClose.name, VsIconClose.component);
  app.component(VsIconShare.name, VsIconShare.component);
  app.component(VsIconPhone.name, VsIconPhone.component);
  app.component(VsIconEmail.name, VsIconEmail.component);
  app.component(VsIconHamburger.name, VsIconHamburger.component);
  app.component(VsIconChevronLeft.name, VsIconChevronLeft.component);
  app.component(VsIconChevronRight.name, VsIconChevronRight.component);
  app.component(VsIconFavoriteFilled.name, VsIconFavoriteFilled.component);
  app.component(VsIconFavoriteOutline.name, VsIconFavoriteOutline.component);
}
