
import { defineComponent, ref } from "vue";
import { routeName } from "@/config/router";
import { useRouter } from "vue-router";

export const name = "logo";

export const component = defineComponent({
  name,
  props: {
    white: {
      type: Boolean,
      required: false,
      default: false,
    },
    red: {
      type: Boolean,
      required: false,
      default: false,
    },
    colored: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    function getActiveColor() {
      if (props.white) {
        return "white";
      } else if (props.red) {
        return "red";
      } else {
        return "colored";
      }
    }
    const $router = useRouter();
    const colorClass = ref(getActiveColor());

    return {
      colorClass,
      goToLanding: () => $router.push({ name: routeName.landing }),
    };
  },
});

export default component;
