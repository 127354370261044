import { Category } from "@/config/app-page";
import { Categories, CategoryId } from "@/config/_app-page/_categories";
import { ref, computed } from "vue";

// Error messages
const CategoryDontExistWithThisId = (categoryId: CategoryId) => `Category with ${categoryId} don't exist.`;
const _allCategories = ref<Category[]>(Categories);
const _activeCategoryId = ref<CategoryId>(CategoryId.Acrobatic);

function getCategory(categoryId: CategoryId) {
  return _allCategories.value.find((cat) => cat.id == categoryId);
}

function getCategoryTitle(categoryId: CategoryId) {
  const category = getCategory(categoryId);

  if (!category) {
    throw new Error(CategoryDontExistWithThisId(categoryId));
  }

  return category.title;
}

function getCategoryByTitle(title: string) {
  return _allCategories.value.find((cat) => cat.title.toLowerCase() == title.toLowerCase());
}

function getRouteToCategoryPosesListPage(categoryId: CategoryId) {
  const category = getCategory(categoryId);

  if (!category) {
    throw new Error(CategoryDontExistWithThisId(categoryId));
  }

  return category;
}

function setActiveCategory(categoryId: CategoryId) {
  _activeCategoryId.value = categoryId;
}

export default function () {
  return {
    getCategory,
    getCategoryByTitle,
    getRouteToCategoryPosesListPage,
    getCategoryTitle,
    allCategories: computed(() => _allCategories.value),
    activeCategoryId: computed(() => _activeCategoryId.value),
    setActiveCategory,
  };
}
