import { StripePriceId } from "@/types/_modules/get-premium";
import { loadStripe } from "@stripe/stripe-js";
import Http from "../utils/http";

const http = Http();

export interface CreateCheckoutSessionPayload {
  priceId: StripePriceId;
}

export function getCustomerPortalUrl() {
  return http.get("stripe/customer-portal");
}

export async function redirectToCheckout(priceId: StripePriceId) {
  const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY as string);

  if (!stripe) {
    throw new Error("Stripe is not defined.");
  }

  const { data } = await http.post("stripe/create-checkout-session", { priceId });

  return stripe.redirectToCheckout({ sessionId: data.sessionId });
}
