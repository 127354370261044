
import { defineComponent } from "vue";

export const name = "vs-icon-chevron-left";

export const component = defineComponent({
  name,
});

export default component;
