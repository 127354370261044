
import { defineComponent } from "vue";
import useAdBlockerChecker from "@/composibles/ad-blocker-checker";
import { Text } from "@/config/global";

export const name = "please-disable-ad-block";

export const component = defineComponent({
  name,
  setup() {
    const { isAdBlockerEnabled } = useAdBlockerChecker();

    return {
      isAdBlockerEnabled,
      titleText: Text.AdBlockPopup.title,
      contentText: Text.AdBlockPopup.content,
    };
  },
});

export default component;
