import { HamburgerMenuContextLevel, TopBarMenuItem } from "@/composibles/top-bar/_use-menu";
import { ModuleIndexForTopBarMenuList } from "@/config";
import { Module } from "@/types/module";
import routes from "./login-router";
import LoginConfig from "@/config/login-page";
import { routeName } from "@/config/router";
import { computed } from "vue";

const topMenuMenuItems: TopBarMenuItem[] = [
  {
    menuItemIndex: ModuleIndexForTopBarMenuList.Login,
    title: LoginConfig.texts.title,
    to: routeName.loginWithEmailLink,
    hamburgerMenuContext: HamburgerMenuContextLevel.DefaultContext,
    isEnabled: computed(() => true),
  },
];

const module: Module = {
  ModuleRoutesConfig: routes,
  topMenuMenuItems,
};

export default module;
