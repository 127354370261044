
import { defineComponent, ref, watch, computed } from "vue";
import useApp from "@/composibles/use-app";
import useTouch from "@/composibles/use-touch";
import { getMainAssetAsBase64, getPoseAssetAsBase64 } from "@/utils/assets";

export const name = "app-content";

export const component = defineComponent({
  name,
  setup() {
    const {
      activeImage,
      loadNextImage,
      loadPreviousImage,
      activeAnimationState,
      AnimationState,
      setAnimationState,
      isTouchDisabled,
      animationTransitions,
      movableLength,
    } = useApp();

    const currentPose = ref<HTMLDivElement>();
    const currentPoseXPosition = ref(0);

    function onTouchEnd() {
      if (currentPose.value && !isTouchDisabled.value) {
        if (activeAnimationState.value == AnimationState.onValidNextImageTouchPosition) {
          loadNextImage();
        } else if (activeAnimationState.value == AnimationState.onValidPreviousImageTouchPosition) {
          loadPreviousImage();
        } else {
          setAnimationState(AnimationState.touchEndWithoutAction);
        }
      }
    }

    const addOpacityFrom = 50; // proc of canTranslate

    const { touchStart, touchMove, touchEnd, translateX } = useTouch(() => {
      /**/
    }, onTouchEnd);

    watch(currentPoseXPosition, (newXPosition) => {
      if (currentPose.value) {
        currentPose.value.style.transition = animationTransitions.value;
        currentPose.value.style.transform = `translateX(${newXPosition}px)`;
      }
    });

    function setOpacity(val: string) {
      if (currentPose.value) {
        currentPose.value.style.opacity = val;
      }
    }

    const opacityOnActivePosition = "0.4";

    watch(activeAnimationState, (newState) => {
      if (currentPose.value) {
        switch (newState) {
          case AnimationState.afterNextImageActionActivated:
            currentPoseXPosition.value = movableLength.value * -1;
            setOpacity("0");
            break;
          case AnimationState.afterPreviousImageActionActivated:
            currentPoseXPosition.value = movableLength.value;
            setOpacity("0");
            break;
          case AnimationState.beforeNextImageLoading:
            currentPoseXPosition.value = movableLength.value;
            setOpacity("0");
            break;
          case AnimationState.beforePreviousImageLoading:
            currentPoseXPosition.value = movableLength.value * -1;
            setOpacity("0");
            break;
          case AnimationState.imageLoaded:
            currentPoseXPosition.value = 0;
            setOpacity("1");
            break;
          case AnimationState.touchEndWithoutAction:
            currentPoseXPosition.value = 0;
            setOpacity("1");
            break;
          case AnimationState.onNotValidActionPosition:
            setOpacity("1");
            break;
          case AnimationState.onValidNextImageTouchPosition:
            setOpacity(opacityOnActivePosition);
            break;
          case AnimationState.onValidPreviousImageTouchPosition:
            setOpacity(opacityOnActivePosition);
            break;
        }
      }
    });

    watch(translateX, (newTranslateX) => {
      if (currentPose.value && !isTouchDisabled.value) {
        if (newTranslateX < movableLength.value && newTranslateX > movableLength.value * -1) {
          currentPoseXPosition.value = parseInt(newTranslateX.toFixed(0)) * -1;

          const startAfter = (movableLength.value * addOpacityFrom) / 100;
          const isPoseInValidNextPoseActionPosition = newTranslateX > startAfter;
          const isPoseInValidPreviousPoseActionPosition = newTranslateX < startAfter * -1;

          if (isPoseInValidNextPoseActionPosition) {
            setAnimationState(AnimationState.onValidNextImageTouchPosition);
          } else if (isPoseInValidPreviousPoseActionPosition) {
            setAnimationState(AnimationState.onValidPreviousImageTouchPosition);
          } else {
            setAnimationState(AnimationState.onNotValidActionPosition);
          }
        }
      }
    });

    const contentStyles = computed(() => `background-image: url("${getMainAssetAsBase64("app/white_circle.svg")}");`);

    return {
      contentStyles,
      currentPose,
      touchStart,
      touchMove,
      touchEnd,
      activeImage,
      getPoseAssetAsBase64,
    };
  },
});

export default component;
