import { Config, ConfigTexts } from "@/types/config";
import { PremiumCard } from "@/types/_modules/get-premium";
import { PoseId } from "@/config/_app-page/_pose-id";
import { color } from "./tailwind";

export type Plan = "solo" | "couple" | "invited";

export interface PremiumPlanConfigTexts {
  title: Plan;
  description: string;
  poseId: PoseId;
  color: color;
}

interface GetPremiumConfigTexts extends ConfigTexts {
  title: string;
  plans: {
    solo: PremiumPlanConfigTexts;
    couple: PremiumPlanConfigTexts;
    invited: PremiumPlanConfigTexts;
  };
}

interface GetPremiumPageConfig extends Config {
  texts: GetPremiumConfigTexts;
  SubscriptionPremiumCards: PremiumCard[];
}

const title = "Get Premium";

const texts: GetPremiumConfigTexts = {
  title,
  plans: {
    solo: {
      title: "solo",
      description: "For the lone-wolves eager to learn & improve.",
      poseId: PoseId.italianChandelier,
      color: color.blue400,
    },
    couple: {
      title: "couple",
      description:
        "For the lucky ones exploring the variety of spices the world has to offer. (Includes access for two)",
      poseId: PoseId.concubine,
      color: color.red300,
    },
    invited: {
      title: "invited",
      description: "You are a part of someone's subscription!",
      poseId: PoseId.piledriver,
      color: color.blue500,
    },
  },
};

const SubscriptionPremiumCards: PremiumCard[] = [
  {
    title: "Get Clicy Premium",
    plan: texts.plans.solo,
    submitButtonText: "CONTINUE",
    priceInCents: 299,
    stripe: {
      priceId: process.env.VUE_APP_STRIPE_SOLO_PRICE_ID as string,
    },
  },
  {
    title: "Get Clicy Premium",
    plan: texts.plans.couple,
    submitButtonText: "CONTINUE",
    priceInCents: 499,
    stripe: {
      priceId: process.env.VUE_APP_STRIPE_COUPLE_PRICE_ID as string,
    },
  },
];

const config: GetPremiumPageConfig = {
  texts,
  SubscriptionPremiumCards,
};

export default config;
