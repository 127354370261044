import Http, { AxiosRequestConfig, AxiosResponse } from "axios";

const baseUrl = process.env.VUE_APP_API_BASE_URL;
const projectId = process.env.VUE_APP_FIREBASE_PROJECT_ID;
const projectRegion = process.env.VUE_APP_FIREBASE_REGION;
let getAuthToken: Function | undefined;

async function requestInterception(config: AxiosRequestConfig) {
  if (!getAuthToken) {
    throw new Error("Please provide getAuthToken callback for Http utility with onTokenRequest function.");
  }

  const token: string = await getAuthToken();

  config.headers["Authorization"] = `Bearer ${token}`;

  return config;
}

async function responseInterceptor(values: AxiosResponse) {
  if (!getAuthToken) {
    throw new Error("Please provide getAuthToken callback for Http utility with onTokenRequest function.");
  }

  // Refresh token after response to check if claims updated
  await getAuthToken();

  return values;
}

export function onTokenRequest(cb: () => Promise<string>) {
  getAuthToken = cb;
}

export default () => {
  let url = "";

  if (process.env.NODE_ENV === "production") {
    url = `https://${projectRegion}-${projectId}.cloudfunctions.net/api/`;
  } else {
    url = `${baseUrl}/${projectId}/${projectRegion}/api/`;
  }

  const http = Http.create({
    baseURL: url,
    timeout: 10000,
  });

  http.interceptors.request.use(requestInterception);
  http.interceptors.response.use(responseInterceptor);

  return http;
};
