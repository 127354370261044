import { color } from "@/config/tailwind";
import { getCustomerPortalUrl } from "@/services/stripe";
import { computed, readonly, ref, watch } from "vue";
import useAuth from "./use-auth";
import MySubscriptionConfig from "@/config/my-subscription-page";
import { Plan } from "@/config/get-premium-page";
import { PoseId } from "@/config/_app-page/_pose-id";
import { sendInvitation, revokeAccess } from "@/services/premium";

const activePlan = {
  enabled: ref(false),
  title: ref<Plan>(MySubscriptionConfig.texts.plans.solo.title),
  color: ref<color>(MySubscriptionConfig.texts.plans.solo.color),
  description: ref<string>(MySubscriptionConfig.texts.plans.solo.description),
  poseId: ref<PoseId>(MySubscriptionConfig.texts.plans.solo.poseId),
};
const partnerEmail = ref<string>("");
const _invitePartnerFormError = ref<string>("");
const _isInviteEmailSent = ref(false);
const _revokeAccessButtonLoading = ref(false);
const _isInviteFormDisabled = ref(false);
const _editSubscriptionButtonLoading = ref(false);
const { premiumPlan, premiumEnabled } = useAuth();

async function invitePartnerSubmit() {
  _isInviteFormDisabled.value = true;

  await sendInvitation({ email: partnerEmail.value }).catch((e) => {
    // eslint-disable-next-line no-console
    console.error(e);
  });

  _isInviteFormDisabled.value = false;
}

async function editSubscriptionSubmit() {
  try {
    _editSubscriptionButtonLoading.value = true;

    const { data } = await getCustomerPortalUrl();

    _editSubscriptionButtonLoading.value = false;

    window.location.href = data.url;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

async function revokeAccessForInvitedPartner() {
  _revokeAccessButtonLoading.value = true;

  await revokeAccess().catch((err) => {
    // eslint-disable-next-line no-console
    console.error(err);
  });

  _revokeAccessButtonLoading.value = false;
}

function changeActivePlanToSolo() {
  activePlan.title.value = MySubscriptionConfig.texts.plans.solo.title;
  activePlan.color.value = MySubscriptionConfig.texts.plans.solo.color;
  activePlan.description.value = MySubscriptionConfig.texts.plans.solo.description;
  activePlan.poseId.value = MySubscriptionConfig.texts.plans.solo.poseId;
}

function changeActivePlanToCouple() {
  activePlan.title.value = MySubscriptionConfig.texts.plans.couple.title;
  activePlan.color.value = MySubscriptionConfig.texts.plans.couple.color;
  activePlan.description.value = MySubscriptionConfig.texts.plans.couple.description;
  activePlan.poseId.value = MySubscriptionConfig.texts.plans.couple.poseId;
}

function changeActivePlanToInvited() {
  activePlan.title.value = MySubscriptionConfig.texts.plans.invited.title;
  activePlan.color.value = MySubscriptionConfig.texts.plans.invited.color;
  activePlan.description.value = MySubscriptionConfig.texts.plans.invited.description;
  activePlan.poseId.value = MySubscriptionConfig.texts.plans.invited.poseId;
}

// watchers
watch(premiumPlan, (plan) => {
  if (plan)
    if (plan.toLowerCase() === "solo") changeActivePlanToSolo();
    else if (plan.toLowerCase() === "couple") changeActivePlanToCouple();
    else if (plan.toLowerCase() === "invited") changeActivePlanToInvited();
});

export default function () {
  return {
    // Sates
    activePlan: {
      enabled: premiumEnabled,
      title: computed(() => activePlan.title.value),
      color: computed(() => activePlan.color.value),
      description: computed(() => activePlan.description.value),
      poseId: computed(() => activePlan.poseId.value),
    },

    invitePartnerSubmit,
    editSubscriptionSubmit,
    revokeAccessForInvitedPartner,

    partnerEmail,
    revokeAccessButtonLoading: readonly(_revokeAccessButtonLoading),
    isInviteEmailSent: readonly(_isInviteEmailSent),
    isInviteFormDisabled: readonly(_isInviteFormDisabled),
    invitePartnerFormError: readonly(_invitePartnerFormError),
    editSubscriptionButtonLoading: readonly(_editSubscriptionButtonLoading),
  };
}
