
import { defineComponent } from "vue";

// Components
import TopBar from "../_components/top-bar.vue";
import AppActions from "@/modules/app/view/_app-view/app-actions.vue";
import Context from "../_components/context.vue";

export const name = "app-layout";
export const component = defineComponent({
  name,
  components: { TopBar, AppActions, Context },
});

export default component;
