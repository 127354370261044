import { RouteRecordRaw } from "vue-router";
import { routeName } from "@/config/router";

import ListLayout from "@/layouts/list-layout/list-layout.vue";
import CategoriesView from "./views/categories-view.vue";
import CategoriesPosesListView from "./views/category-poses-list-view.vue";
import { computed } from "vue";
import useLogin from "@/composibles/use-login";

const routes: RouteRecordRaw[] = [
  {
    path: "/categories/",
    component: ListLayout,
    children: [
      {
        name: routeName.categories,
        path: "",
        component: CategoriesView,
        meta: {
          isValidForUser: [
            {
              fallbackUrl: routeName.loginWithEmailLink,
              valid: computed(() => useLogin().isUserLoggedIn.value),
            },
          ],
        },
      },
      {
        name: routeName.categoryPosesList,
        path: ":CategorySlug",
        component: CategoriesPosesListView,
        meta: {
          isValidForUser: [
            {
              fallbackUrl: routeName.loginWithEmailLink,
              valid: computed(() => useLogin().isUserLoggedIn.value),
            },
          ],
        },
      },
    ],
  },
];
export default routes;
