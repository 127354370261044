
import { defineComponent, computed } from "vue";

// Configs
import { routeName } from "@/config/router";
import AppConfig from "@/config/app-page";

// Composibles
import useApp from "@/composibles/use-app";
import usePoseShare from "@/composibles/use-pose-share";
import { useRoute } from "vue-router";

export const name = "share-button";

export const component = defineComponent({
  name,
  setup() {
    const $route = useRoute();
    const { copyPoseUrl, getPoseShareUrl, isCopyToClipboardVisible } = usePoseShare();

    const isAppPage = computed(() => $route.name == routeName.app);

    function onClickShare() {
      const { getPoseIdForSharing } = useApp();

      const url = getPoseShareUrl(location.origin, getPoseIdForSharing());

      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (navigator.share) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        navigator.share({ url, title: AppConfig.texts.sharePose.title, text: AppConfig.texts.sharePose.text });
      } else {
        copyPoseUrl(url);
      }
    }

    return { isAppPage, onClickShare, isCopyToClipboardVisible };
  },
});

export default component;
