import { ref, computed } from "vue";
import useTailwind from "@/composibles/use-tailwind";
import { color } from "@/config/tailwind";

const activeBgColorClass = ref(`bg-${color.red300}`);

export default function () {
  const { getColorValue } = useTailwind();

  function changeBgColorClass(bgClass: color) {
    activeBgColorClass.value = "bg-" + bgClass;
    const metaElement = document.querySelector('meta[name="theme-color"]');

    if (metaElement) {
      metaElement.setAttribute("content", getColorValue(bgClass));
    }
  }

  return {
    changeBgColorClass,
    bgColorClass: computed(() => activeBgColorClass.value),
  };
}
