import { Config, ConfigTexts } from "@/types/config";

interface AboutUsConfig extends Config {
  texts: AboutUsConfigTexts;
}

interface AboutUsConfigTexts extends ConfigTexts {
  title: string;
  description: string;
}

const title = "About us";

const texts: AboutUsConfigTexts = {
  title,
  description: `Treat Clicy as a sex-related library and use it to expand your sexual horizons and have fun experimenting! Within the library, we refer to 'she' for the 'receiving' partner and 'he' for the 'giving' partner; by no means, this should be gender-bending as most of the content are reverse-role compatible and LGBTQ friendly (with explicit content coming soon).`,
};

const config: AboutUsConfig = { texts };

export default config;
