import Http from "@/utils/http";

const http = Http();

export function sendInvitation({ email }: { email: string }) {
  return http.post("premium-invitation/invite", { email });
}

export function revokeAccess() {
  return http.post("premium-invitation/revoke-access");
}
