
import { defineComponent } from "vue";

export const name = "vs-icon-email";

export const component = defineComponent({
  name,
});

export default component;
