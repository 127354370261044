import assets from "@/assets/images/images-assets.json";
import { getPremiumPosesAssets } from "@/services/firebase";

let index = 1;
let rawPremiumAssets: { [index: string]: string } = {};

export async function downloadPremiumAssets() {
  if (!Object.keys(rawPremiumAssets).length) {
    rawPremiumAssets = await getPremiumPosesAssets();
  }
}

export function getMainAssetAsBase64(imagePath: string): string {
  const content = assets[imagePath] ?? rawPremiumAssets["poses/" + imagePath];

  return content;
}

export function getPoseAssetAsBase64(imagePath: string): string {
  if (process.env.NODE_ENV == "development" && process.env.VUE_APP_NSFW === "true") {
    if (index == 1) {
      index = 0;
      return require("@/assets/archer.svg") as string;
    } else {
      index = 1;
      return require("@/assets/yang.svg") as string;
    }
  } else {
    return getMainAssetAsBase64(imagePath);
  }
}

export function getPremiumPoseAssetAsBase64(imagePath: string): string {
  return rawPremiumAssets[imagePath];
}
