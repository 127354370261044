// Routes
import { routeName } from "@/config/router";

// Layouts
import MailLayout from "@/layouts/main-layout/main-layout.vue";

// Views
import GetPremiumView from "./views/get-premium-view.vue";

// Config
import GetPremiumConfig from "@/config/get-premium-page";

// Types
import { Module } from "@/types/module";
import { RouteRecordRaw } from "vue-router";
import { HamburgerMenuContextLevel, TopBarMenuItem } from "@/composibles/top-bar/_use-menu";
import { ModuleIndexForTopBarMenuList } from "@/config";
import useLogin from "@/composibles/use-login";
import useAuth from "@/composibles/use-auth";
import { computed } from "vue";
import config from "@/config/get-premium-page";

// Views

const routes: RouteRecordRaw[] = [
  {
    path: "/get-premium",
    component: MailLayout,
    children: [
      {
        name: routeName.getPremium,
        path: "",
        component: GetPremiumView,
        meta: {
          pageTitle: config.texts.title,
          addTitleBottomBorder: false,
          isValidForUser: [
            {
              fallbackUrl: routeName.loginWithEmailLink,
              valid: useLogin().isUserLoggedIn,
            },
            {
              fallbackUrl: routeName.mySubscription,
              valid: computed(() => useAuth().premiumEnabled.value === false),
            },
          ],
        },
      },
    ],
  },
];

const topMenuMenuItems: TopBarMenuItem[] = [
  {
    menuItemIndex: ModuleIndexForTopBarMenuList.GetPremium,
    title: GetPremiumConfig.texts.title,
    to: routeName.getPremium,
    hamburgerMenuContext: HamburgerMenuContextLevel.DefaultContext,
    isEnabled: computed(() => useLogin().isUserLoggedIn.value && !useAuth().premiumEnabled.value),
  },
];

const module: Module = {
  ModuleRoutesConfig: routes,
  topMenuMenuItems,
};

export default module;
