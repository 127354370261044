
import useBackground from "@/composibles/use-background";
import { defineComponent } from "vue";
import GetPremiumConfig from "@/config/get-premium-page";
import useTailwind from "@/composibles/use-tailwind";

// Components
import SubPremiumCardComponent from "../components/sub-premium-card-component.vue";
import CarouselComponent from "../components/carousel-component.vue";
import { color } from "@/config/tailwind";

export const name = "get-premium-view";

export const component = defineComponent({
  name,
  components: {
    SubPremiumCardComponent,
    CarouselComponent,
  },
  setup() {
    useBackground().changeBgColorClass(color.white);

    const { isMobile } = useTailwind();

    return {
      SubscriptionPremiumCards: GetPremiumConfig.SubscriptionPremiumCards,

      isMobile,
    };
  },
});

export default component;
