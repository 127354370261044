import { ModuleIndexForTopBarMenuList } from "./index";
import { Config, ConfigTexts } from "@/types/config";

interface TopBarConfigTexts extends ConfigTexts {
  loginBtn: string;
  logoutBtn: string;
}
interface MenuSettings {
  landingMenuItemIndexes: ModuleIndexForTopBarMenuList[];
  hamburgerMenuItemIndexes: ModuleIndexForTopBarMenuList[];
}

interface TopBarConfig extends Config {
  texts: TopBarConfigTexts;
  menuSettings: MenuSettings;
}

const texts: TopBarConfigTexts = {
  loginBtn: "Log in",
  logoutBtn: "Log out",
};

const menuSettings: MenuSettings = {
  // Defines item position in menu list, just change position in the list
  landingMenuItemIndexes: [
    ModuleIndexForTopBarMenuList.AboutUs,
    ModuleIndexForTopBarMenuList.ContactUs,
    ModuleIndexForTopBarMenuList.Legal,
    ModuleIndexForTopBarMenuList.MySubscription,
    ModuleIndexForTopBarMenuList.GetPremium,
  ],
  hamburgerMenuItemIndexes: [
    ModuleIndexForTopBarMenuList.Play,
    ModuleIndexForTopBarMenuList.Categories,
    ModuleIndexForTopBarMenuList.Favorites,

    ModuleIndexForTopBarMenuList.Landing,
    ModuleIndexForTopBarMenuList.AboutUs,
    ModuleIndexForTopBarMenuList.ContactUs,
    ModuleIndexForTopBarMenuList.Legal,
    ModuleIndexForTopBarMenuList.GetPremium,
    ModuleIndexForTopBarMenuList.MySubscription,
  ],
};

const config: TopBarConfig = {
  menuSettings,
  texts,
};

export default config;
