
import { defineComponent } from "vue";

// Components
import TopBar from "../_components/top-bar.vue";

export const name = "landing-layout";

export const component = defineComponent({
  name,
  components: { TopBar },
});

export default component;
