import { RouteRecordRaw } from "vue-router";
import { routeName } from "@/config/router";

import AppView from "./view/app-view.vue";
import AppLayout from "@/layouts/app-layout/app-layout.vue";
import { computed } from "vue";
import useLogin from "@/composibles/use-login";

const routes: RouteRecordRaw[] = [
  {
    path: "/play",
    component: AppLayout,
    children: [
      {
        path: "",
        name: routeName.app,
        component: AppView,
        meta: {
          isValidForUser: [
            { valid: computed(() => useLogin().isUserLoggedIn.value), fallbackUrl: routeName.loginWithEmailLink },
          ],
        },
      },
    ],
  },
];

export default routes;
