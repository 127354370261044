// Routes
import routes from "./favorites-router";
import { Module } from "@/types/module";
import { HamburgerMenuContextLevel, TopBarMenuItem } from "@/composibles/top-bar/_use-menu";
import { ModuleIndexForTopBarMenuList } from "@/config";
import FavoritesConfig from "@/config/favorite-poses-list-page";
import { routeName } from "@/config/router";
import useApp from "@/composibles/use-app";
import { computed } from "vue";

const topMenuMenuItems: TopBarMenuItem[] = [
  {
    menuItemIndex: ModuleIndexForTopBarMenuList.Favorites,
    title: FavoritesConfig.texts.title,
    to: routeName.favorites,
    onClick: () => {
      useApp().changeContextToDefault();
    },
    isEnabled: computed(() => true),
    hamburgerMenuContext: HamburgerMenuContextLevel.AppContext,
  },
];

const module: Module = {
  ModuleRoutesConfig: routes,
  topMenuMenuItems,
};

export default module;
