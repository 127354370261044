
import { defineComponent, onMounted } from "vue";
import LandingConfig from "@/config/landing-page";
import useBackground from "@/composibles/use-background";
import { getMainAssetAsBase64 } from "@/utils/assets";
import useTailwind from "@/composibles/use-tailwind";
import { routeName } from "../../../config/router";
import useLogin from "../../../composibles/use-login";
import { color } from "@/config/tailwind";

export const name = "landing-view";

export const component = defineComponent({
  name,
  setup() {
    const { isMobile } = useTailwind();
    const { isUserLoggedIn } = useLogin();

    onMounted(() => useBackground().changeBgColorClass(color.gray200));

    const wave1Style = `background-image: url("${getMainAssetAsBase64("landing/wave-1.svg")}");`;
    const wave2Style = `background-image: url("${getMainAssetAsBase64("landing/wave-2.svg")}");`;
    const wave3Style = `background-image: url("${getMainAssetAsBase64("landing/wave-3.svg")}");`;
    const wave4Style = `background-image: url("${getMainAssetAsBase64("landing/wave-4.svg")}");`;
    const circle2Style = `background-image: url("${getMainAssetAsBase64("landing/circle-2.svg")}");`;
    const circle3Style = `background-image: url("${getMainAssetAsBase64("landing/circle-3.svg")}");`;
    const circle4Style = `background-image: url("${getMainAssetAsBase64("landing/circle-4.svg")}");`;
    const circle5Style = `background-image: url("${getMainAssetAsBase64("landing/circle-5.svg")}");`;
    const circle7Style = `background-image: url("${getMainAssetAsBase64("landing/circle-7.svg")}");`;
    const tlfMockStyle = `background-image: url("${getMainAssetAsBase64("landing/tlf-mock.svg")}");`;
    const androidStyle = `background-image: url("${getMainAssetAsBase64("landing/android.svg")}");`;
    const appleStyle = `background-image: url("${getMainAssetAsBase64("landing/apple.svg")}");`;

    const isAppleStoreEnabled = process.env.VUE_APP_LANDING_APPLE_STORE_ENABLED === "true";
    const isAndroidStoreEnabled = process.env.VUE_APP_LANDING_ANDROID_STORE_ENABLED === "true";

    return {
      texts: LandingConfig.texts,
      isMobile,
      loginRoute: { name: routeName.loginWithEmailLink },
      playRoute: { name: routeName.app },
      isUserLoggedIn,

      // Assets
      wave1Style,
      wave2Style,
      wave3Style,
      wave4Style,
      circle2Style,
      circle3Style,
      circle4Style,
      circle5Style,
      circle7Style,
      androidStyle,
      appleStyle,
      tlfMockStyle,
      isAppleStoreEnabled,
      isAndroidStoreEnabled,
    };
  },
});

export default component;
