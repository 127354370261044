
import { defineComponent, watch, ref } from "vue";
import useApp from "@/composibles/use-app";
import useCategories from "@/composibles/use-categories";

export const name = "app-description";

export const component = defineComponent({
  name,
  setup() {
    const loadClass = ref("");
    const container = ref<HTMLDivElement>();
    const { getCategoryTitle, getRouteToCategoryPosesListPage } = useCategories();
    const { activeImage, activeAnimationState, AnimationState, animationTransitions, movableLength } = useApp();

    watch(activeAnimationState, (newState) => {
      if (container.value) {
        container.value.style.transition = animationTransitions.value;

        switch (newState) {
          case AnimationState.imageLoaded:
            container.value.style.transform = `translateX(0)`;
            container.value.style.opacity = "1";
            break;
          case AnimationState.afterNextImageActionActivated:
            container.value.style.transform = `translateX(${movableLength.value * -1}px)`;
            container.value.style.opacity = "0";
            break;
          case AnimationState.afterPreviousImageActionActivated:
            container.value.style.transform = `translateX(${movableLength.value}px)`;
            container.value.style.opacity = "0";
            break;
          case AnimationState.beforeNextImageLoading:
            container.value.style.transform = `translateX(${movableLength.value}px)`;
            container.value.style.opacity = "0";
            break;
          case AnimationState.beforePreviousImageLoading:
            container.value.style.transform = `translateX(${movableLength.value * -1}px)`;
            container.value.style.opacity = "0";
            break;
        }
      }
    });

    return { container, loadClass, activeImage, getCategoryTitle, getRouteToCategoryPosesListPage };
  },
});

export default component;
