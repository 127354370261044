import { Config, ConfigTexts } from "@/types/config";
import GetPremiumPageConfig, { PremiumPlanConfigTexts } from "./get-premium-page";

interface MySubscriptionConfigTexts extends ConfigTexts {
  title: string;
  plans: {
    solo: PremiumPlanConfigTexts;
    couple: PremiumPlanConfigTexts;
    invited: PremiumPlanConfigTexts;
  };
  isInviteEmailSent: string;
  invitePartnerSubmitButtonText: string;
  invitePartnerEmailInputPlaceholder: string;
  unsubscribeFormButtonText: string;
  editSubscriptionFormButtonText: string;
  invitedPartnerEmailPretext: string;
  revokeAccessText: string;
  invitedByText: string;
  subscriptionEnds: string;
  nextInvoice: string;
}

interface MySubscriptionConfig extends Config {
  texts: MySubscriptionConfigTexts;
}

const title = "My Subscription";

const texts: MySubscriptionConfigTexts = {
  title,
  plans: GetPremiumPageConfig.texts.plans,
  isInviteEmailSent: "Email sent. Partner can check his/her email box.",
  invitePartnerSubmitButtonText: "Invite",
  invitePartnerEmailInputPlaceholder: "Enter email",
  unsubscribeFormButtonText: "Unsubscribe",
  editSubscriptionFormButtonText: "Edit Subscription",
  invitedPartnerEmailPretext: "Your partner is:",
  revokeAccessText: "Revoke access",
  invitedByText: "You are invited by:",
  subscriptionEnds: "Subscription ends",
  nextInvoice: "Next invoice",
};

const config: MySubscriptionConfig = {
  texts,
};

export default config;
