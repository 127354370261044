// use this only in creating module only once per module, sequence do not matter
export enum ModuleIndexForTopBarMenuList {
  Legal,
  MySubscription,
  Landing,
  GetPremium,
  Favorites,
  ContactUs,
  Categories,
  Play,
  AboutUs,
  Login,
}
