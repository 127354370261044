import { Config, ConfigTexts } from "@/types/config";

interface LoginConfigTexts extends ConfigTexts {
  title: "Log in";
  emailSent: string;
  buttonText: string;
  policyAndTerms: {
    textStart: string;
    termsLinkText: string;
    betweenTermsAndPolicyText: string;
    policyLinkText: string;
    textEnd: string;
  };
  error: {
    noEmilForLogin: string;
  };
}

interface LoginConfig extends Config {
  texts: LoginConfigTexts;
}

const texts: LoginConfigTexts = {
  title: "Log in",
  emailSent: "Email sent. Check your email box.",
  buttonText: "Get login link",
  policyAndTerms: {
    textStart: "By signing up, you agree to ",
    termsLinkText: "Terms of Service",
    betweenTermsAndPolicyText: " and ",
    policyLinkText: "Privacy Policy",
    textEnd: ".",
  },
  error: {
    noEmilForLogin: "Error. Maybe you used another app/browser for login?",
  },
};

const config: LoginConfig = {
  texts,
};

export default config;
