
import { defineComponent } from "vue";
import usePremium from "@/composibles/use-premium";
import MySubscriptionConfig from "@/config/my-subscription-page";

export const name = "edit-subscription-form";

export const component = defineComponent({
  name,
  setup() {
    const { editSubscriptionSubmit, editSubscriptionButtonLoading } = usePremium();

    return {
      editSubscriptionButtonLoading,
      editSubscriptionFormButtonText: MySubscriptionConfig.texts.editSubscriptionFormButtonText,
      editSubscriptionSubmit,
    };
  },
});

export default component;
