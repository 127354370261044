import { ref, computed } from "vue";

let _cbOnTouchEnd: Function | null = null;
let _cbOnTouchStart: Function | null = null;

const touchStartX = ref(0);
const touchStartY = ref(0);
const touchEndX = ref(0);
const touchEndY = ref(0);
const translateX = ref(0);
const translateY = ref(0);
let _disableXScroll = false;
let _disableYScroll = false;

function touchStart(e: TouchEvent) {
  // if (e.cancelable) e.preventDefault();

  touchStartX.value = e.changedTouches[0].clientX;
  touchStartY.value = e.changedTouches[0].clientY;

  if (_cbOnTouchStart) {
    _cbOnTouchStart(e.changedTouches[0].clientX, e.changedTouches[0].clientX);
  }
}

function touchMove(e: TouchEvent) {
  // if (e.cancelable) e.preventDefault();

  const Y = touchStartY.value - e.changedTouches[0].clientY;
  const X = touchStartX.value - e.changedTouches[0].clientX;

  if ((-5 > Y || Y > 5) && !_disableYScroll) {
    _disableXScroll = true;
    translateY.value = Y;
  }

  if ((X < -5 || X > 5) && !_disableXScroll) {
    _disableYScroll = true;
    translateX.value = X;
  }
}

function touchEnd(e: TouchEvent) {
  if (e.cancelable) e.preventDefault();

  touchEndX.value = e.changedTouches[0].clientX;
  touchEndY.value = e.changedTouches[0].clientY;

  if (_cbOnTouchEnd) {
    _cbOnTouchEnd(e.changedTouches[0].clientX, e.changedTouches[0].clientX);
  }

  _disableXScroll = false;
  _disableYScroll = false;
}

export default function (cbOnTouchStart: CallableFunction, cbOnTouchEnd: CallableFunction) {
  _cbOnTouchStart = cbOnTouchStart;
  _cbOnTouchEnd = cbOnTouchEnd;

  return {
    touchStart,
    touchMove,
    touchEnd,
    translateX: computed(() => translateX.value),
    translateY: computed(() => translateY.value),
  };
}
