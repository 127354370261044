import { ref, onMounted, onBeforeUnmount, computed, watch, ComputedRef } from "vue";
import useTailwind from "../use-tailwind";
import { routeName } from "@/config/router";
import { Router, useRouter } from "vue-router";
import { ModuleIndexForTopBarMenuList } from "@/config";
import TopBarConfig from "@/config/top-bar";
import { registeredTopBarMenuItems } from "@/modules/index";

export enum HamburgerMenuContextLevel {
  NONE,
  AppContext,
  DefaultContext,
}

export type MenuItemTitle = string;
export interface ConfigMenuItem {
  title: MenuItemTitle;
}

export interface TopBarMenuItem {
  to: routeName;
  title: MenuItemTitle;
  onClick?: CallableFunction;
  menuItemIndex: ModuleIndexForTopBarMenuList;
  isEnabled: ComputedRef<boolean>;
  hamburgerMenuContext: HamburgerMenuContextLevel;
}

enum MenuType {
  hamburger,
  landing,
}

let _router: Router;
let _isMenuItemsRegistered = false;
const _isMenuActive = ref(false);
let _landingMenuList: Array<TopBarMenuItem> = [];
let _hamburgerMenuList: Array<TopBarMenuItem> = [];

function _getActiveMenuType(isMobile: boolean) {
  if (_router && _router.currentRoute && _router.currentRoute.value.name == routeName.landing && !isMobile) {
    return MenuType.landing;
  } else {
    return MenuType.hamburger;
  }
}

const _activeMenuType = ref<MenuType>();

function _closeMenu(e: Event | undefined | null = null) {
  if (e) {
    e.preventDefault();
  }

  _isMenuActive.value = false;
}

function _onEsc(e: KeyboardEvent) {
  if (_isMenuActive.value && (e.key.toLocaleLowerCase() == "esc" || e.key.toLocaleLowerCase() == "escape")) {
    _closeMenu();
  }
}

function _refreshMenuType(isMobile: boolean) {
  _activeMenuType.value = _getActiveMenuType(isMobile);
}

function openMenu() {
  _isMenuActive.value = true;
}

function _registerMenuItem(item: TopBarMenuItem) {
  if (TopBarConfig.menuSettings.landingMenuItemIndexes.includes(item.menuItemIndex)) {
    _landingMenuList.push(item);
  }

  if (TopBarConfig.menuSettings.hamburgerMenuItemIndexes.includes(item.menuItemIndex)) {
    _hamburgerMenuList.push(item);
  }
}

function _sortMenuItems() {
  _landingMenuList = TopBarConfig.menuSettings.landingMenuItemIndexes.map((item) => {
    const menuItem = _landingMenuList.find((menuItem) => menuItem.menuItemIndex === item);

    return menuItem as TopBarMenuItem;
  });

  _hamburgerMenuList = TopBarConfig.menuSettings.hamburgerMenuItemIndexes.map((item) => {
    const menuItem = _hamburgerMenuList.find((menuItem) => menuItem.menuItemIndex == item);

    return menuItem as TopBarMenuItem;
  });
}

export default function () {
  _router = useRouter();
  const { isMobile } = useTailwind();

  _refreshMenuType(isMobile.value);

  // Watchers
  watch(isMobile, (val) => _refreshMenuType(val));

  onMounted(() => window.addEventListener("keydown", _onEsc));
  onBeforeUnmount(() => window.removeEventListener("keydown", _onEsc));

  if (!_isMenuItemsRegistered) {
    registeredTopBarMenuItems.forEach((item) => {
      _registerMenuItem(item);
    });

    _isMenuItemsRegistered = true;
    _sortMenuItems();
  }

  return {
    // Enum
    MenuType,

    // Values
    isMenuActive: computed(() => _isMenuActive.value),
    activeMenuType: computed(() => _activeMenuType.value),

    // readonly values
    landingMenuList: _landingMenuList,
    hamburgerMenuList: _hamburgerMenuList,

    // Mutations
    openMenu,
    closeMenu: _closeMenu,
  };
}
