
import { defineComponent, onMounted } from "vue";
import useBackground from "@/composibles/use-background";
import { getMainAssetAsBase64 } from "@/utils/assets";
import useTailwind from "@/composibles/use-tailwind";
import useLogin from "@/composibles/use-login";
import LoginConfig from "@/config/login-page";
import { routeName } from "@/config/router";
import { useRouter } from "vue-router";
import { color } from "@/config/tailwind";

export const name = "login-get-code-view-confirm";

export const component = defineComponent({
  name,
  setup() {
    const $router = useRouter();
    const { isMobile } = useTailwind();
    const { confirmLogin } = useLogin();

    onMounted(async () => {
      useBackground().changeBgColorClass(color.gray200);

      try {
        await confirmLogin();

        $router.push({ name: routeName.app });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);

        $router.push({ name: routeName.loginWithEmailLink });
      }
    });

    const wave1Style = `background-image: url("${getMainAssetAsBase64("login/get-code/wave-1.svg")}");`;
    const circle1Style = `background-image: url("${getMainAssetAsBase64("login/get-code/circle-1.svg")}");`;
    const circle2Style = `background-image: url("${getMainAssetAsBase64("login/get-code/circle-2.svg")}");`;
    const circle3Style = `background-image: url("${getMainAssetAsBase64("login/get-code/circle-3.svg")}");`;

    return {
      texts: LoginConfig.texts,
      isMobile,
      routeName,

      // Assets
      wave1Style,
      circle1Style,
      circle2Style,
      circle3Style,
    };
  },
});

export default component;
