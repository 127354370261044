import { RouteRecordRaw } from "vue-router";
import { Module } from "@/types/module";
import { TopBarMenuItem } from "@/composibles/top-bar/_use-menu";

// Modules
import LandingModule from "./landing/landing-module";
import LegalModule from "./legal/legal-model";
import AppModule from "./app/app-module";
import CategoriesModule from "./categories/categories-model";
import FavoritesModule from "./favorites/favorites-model";
import ContactUsModule from "./contact-us/contact-us-model";
import LoginModule from "./login/login-model";
import AboutUsModule from "./about-us/about-us-model";
import GetPremiumModule from "./get-premium/get-premium-model";
import MySubscription from "./my-subscription/my-subscription-module";

/**
 * Declare all modules here
 */
const modules: Module[] = [
  LandingModule,
  LegalModule,
  AppModule,
  CategoriesModule,
  FavoritesModule,
  ContactUsModule,
  LoginModule,
  AboutUsModule,
  GetPremiumModule,
  MySubscription,
];

// Register module landing menu items
export const registeredTopBarMenuItems: TopBarMenuItem[] = modules.flatMap((module) => {
  if (module.topMenuMenuItems) {
    return module.topMenuMenuItems;
  }

  return [];
});

// Flatten modules routes arrays
export const Routes: RouteRecordRaw[] = modules.flatMap((module) => module.ModuleRoutesConfig);
