
import { computed, defineComponent } from "vue";

// Components
import TopBar from "../_components/top-bar.vue";
import PageTitle from "@/components/page-title.vue";
import { routeName } from "@/config/router";
import useTailwind from "@/composibles/use-tailwind";
import { breakPointNames } from "@/config/tailwind";
import { useRoute } from "vue-router";

export const name = "main-layout";

export const component = defineComponent({
  name,
  components: { TopBar, PageTitle },
  setup() {
    const $route = useRoute();
    const { isBreakPoint } = useTailwind();

    const addTitleBottomBorder = computed(() => {
      return $route.meta.addTitleBottomBorder === true || $route.meta.addTitleBottomBorder === undefined;
    });

    const pageTitleEnabled = computed(() => {
      if (
        $route.name &&
        $route.name == routeName.getPremium &&
        isBreakPoint([breakPointNames.xs, breakPointNames.sm, breakPointNames.md])
      ) {
        return false;
      } else {
        return true;
      }
    });

    return { pageTitleEnabled, addTitleBottomBorder };
  },
});

export default component;
