import { Module } from "@/types/module";
import MainLayout from "@/layouts/main-layout/main-layout.vue";
import { routeName } from "@/config/router";
import MySubscriptionView from "../my-subscription/views/my-subscription-view.vue";
import { RouteRecordRaw } from "vue-router";
import MySubscriptionConfig from "@/config/my-subscription-page";
import { HamburgerMenuContextLevel, TopBarMenuItem } from "@/composibles/top-bar/_use-menu";
import { ModuleIndexForTopBarMenuList } from "@/config";
import useLogin from "@/composibles/use-login";
import useAuth from "@/composibles/use-auth";
import { computed } from "vue";

const routes: RouteRecordRaw[] = [
  {
    component: MainLayout,
    path: `/${routeName.mySubscription}`,
    children: [
      {
        path: "",
        name: routeName.mySubscription,
        component: MySubscriptionView,
        meta: {
          pageTitle: MySubscriptionConfig.texts.title,
          addTitleBottomBorder: false,
          isValidForUser: [
            {
              fallbackUrl: routeName.loginWithEmailLink,
              valid: useLogin().isUserLoggedIn,
            },
            {
              fallbackUrl: routeName.getPremium,
              valid: useAuth().premiumEnabled,
            },
          ],
        },
      },
    ],
  },
];

const topMenuMenuItems: TopBarMenuItem[] = [
  {
    menuItemIndex: ModuleIndexForTopBarMenuList.MySubscription,
    title: MySubscriptionConfig.texts.title,
    to: routeName.mySubscription,
    hamburgerMenuContext: HamburgerMenuContextLevel.DefaultContext,
    isEnabled: computed(() => useLogin().isUserLoggedIn.value && useAuth().premiumEnabled.value),
  },
];

const module: Module = {
  ModuleRoutesConfig: routes,
  topMenuMenuItems,
};

export default module;
