import { ref, computed, watch } from "vue";
import { shuffleList } from "@/utils/helpers";
import { saveFavoritePoseId, onFavoritePoseChange, removeFavoritePoseId } from "@/services/firebase";
import { Pose } from "@/config/app-page";
import { PoseId } from "@/config/_app-page/_pose-id";
import { Poses } from "@/config/_app-page/_poses";
import { PremiumPoses } from "@/config/_app-page/_premium-poses";
import { CategoryId } from "@/config/_app-page/_categories";

const LS_APP_SHARED_POSE_ID = "LS_APP_SHARED_POSE_ID";
let allPoses: Pose[] = [];

enum AnimationState {
  beforeNextImageLoading,
  beforePreviousImageLoading,
  imageLoaded,
  touchEndWithoutAction,
  onNotValidActionPosition,
  onValidNextImageTouchPosition,
  onValidPreviousImageTouchPosition,
  afterNextImageActionActivated,
  afterPreviousImageActionActivated,
}

function findPoseById(id: PoseId): Pose {
  return allPoses.find((pose) => id == pose.id) as Pose;
}

enum Context {
  Default,
  Categories,
  Favorites,
}

let _isActionsDisabled = false;
let _activePosesListIndex = 0;
const _animationSpeed = 150;
const _canTranslateProc = 25; // proc of vw
const _activeContext = ref<Context>(Context.Default);
const _fullListOfRandomPosesIds = ref<PoseId[]>();
const _activePoseId = ref<PoseId>();
const _favoritesPosesIdsList = ref<PoseId[]>([]);
let _favoritesListenerRegister = false;
const _activeImage = ref<Pose>();
const _animationState = ref(AnimationState.imageLoaded);

function setAnimationState(state: AnimationState) {
  if (_animationState.value !== state) {
    _animationState.value = state;
  }
}

watch(_activePoseId, async (newVal) => {
  if (newVal) {
    await setTimeout(() => (_activeImage.value = findPoseById(newVal)), _animationSpeed);
    await setTimeout(() => setAnimationState(AnimationState.imageLoaded), _animationSpeed);

    if (_animationState.value === AnimationState.afterNextImageActionActivated) {
      setAnimationState(AnimationState.beforeNextImageLoading);
    } else if (_animationState.value === AnimationState.afterPreviousImageActionActivated) {
      setAnimationState(AnimationState.beforePreviousImageLoading);
    }

    _isActionsDisabled = false;
  }
});

function _loadNextImage() {
  if (_fullListOfRandomPosesIds.value) {
    if (_activePosesListIndex == _fullListOfRandomPosesIds.value.length - 1) {
      _activePosesListIndex = 0;
      _activePoseId.value = _fullListOfRandomPosesIds.value[_activePosesListIndex];
    } else {
      _activePosesListIndex = _activePosesListIndex + 1;
      _activePoseId.value = _fullListOfRandomPosesIds.value[_activePosesListIndex];
    }
  }
}

function _loadPreviousImage() {
  if (_fullListOfRandomPosesIds.value) {
    if (_activePosesListIndex == 0) {
      _activePosesListIndex = _fullListOfRandomPosesIds.value.length - 1;
      _activePoseId.value = _fullListOfRandomPosesIds.value[_activePosesListIndex];
    } else {
      _activePosesListIndex = _activePosesListIndex - 1;
      _activePoseId.value = _fullListOfRandomPosesIds.value[_activePosesListIndex];
    }
  }
}

function loadNextImage() {
  if (_isActionsDisabled) {
    return;
  }

  if (_fullListOfRandomPosesIds.value) {
    if (_fullListOfRandomPosesIds.value.length > 1) {
      _isActionsDisabled = true;
      setAnimationState(AnimationState.afterNextImageActionActivated);
      setTimeout(_loadNextImage, _animationSpeed);
    } else {
      setAnimationState(AnimationState.touchEndWithoutAction);
    }
  }
}

function loadPreviousImage() {
  if (_isActionsDisabled) {
    return;
  }

  if (_fullListOfRandomPosesIds.value) {
    if (_fullListOfRandomPosesIds.value.length > 1) {
      _isActionsDisabled = true;
      setAnimationState(AnimationState.afterPreviousImageActionActivated);
      setTimeout(() => _loadPreviousImage(), _animationSpeed);
    } else {
      setAnimationState(AnimationState.touchEndWithoutAction);
    }
  }
}

async function markAsFavorite(id: PoseId) {
  try {
    await saveFavoritePoseId(id);
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    _favoritesPosesIdsList.value.push(id);
  }
}

function changeContextToDefault() {
  _fullListOfRandomPosesIds.value = shuffleList(allPoses.map((pose) => pose.id));
  _activePosesListIndex = 0;
  _activeContext.value = Context.Default;
  _activePoseId.value = _fullListOfRandomPosesIds.value[_activePosesListIndex];
}

async function removeFromFavorites(id: PoseId) {
  try {
    await removeFavoritePoseId(id);
  } catch (e) {
    _favoritesPosesIdsList.value = _favoritesPosesIdsList.value.filter((poseId) => poseId != id);
  }

  if (_favoritesPosesIdsList.value.length == 0) {
    changeContextToDefault();
  } else if (_activeContext.value == Context.Favorites) {
    loadNextImage();
    _fullListOfRandomPosesIds.value = shuffleList(_favoritesPosesIdsList.value);
    _activePosesListIndex = _fullListOfRandomPosesIds.value.findIndex((poseId) => poseId == _activePoseId.value);
  }
}

function isFavorite(id: PoseId) {
  return _favoritesPosesIdsList.value.includes(id);
}

function changeContextToCategory(pose: Pose, categoryId: CategoryId) {
  _activePoseId.value = pose.id;
  _activeContext.value = Context.Categories;

  const categoryContextPosesIds = allPoses
    .filter((pose) => pose.categoriesIds.includes(categoryId))
    .map((pose) => pose.id);

  _fullListOfRandomPosesIds.value = shuffleList(categoryContextPosesIds);

  _activePosesListIndex = _fullListOfRandomPosesIds.value.findIndex((poseId) => poseId == _activePoseId.value);
}

function changeContextToFavorites(pose: Pose) {
  _activePoseId.value = pose.id;
  _activeContext.value = Context.Favorites;

  _fullListOfRandomPosesIds.value = shuffleList(_favoritesPosesIdsList.value);
  _activePosesListIndex = _fullListOfRandomPosesIds.value.findIndex((poseId) => poseId == _activePoseId.value);
}

function onKeyUp(e: KeyboardEvent) {
  if (e.isComposing || e.keyCode === 229) {
    return;
  }
  if (_activeImage.value) {
    switch (e.code) {
      case "ArrowRight":
        loadNextImage();
        break;
      case "KeyL":
        loadNextImage();
        break;
      case "ArrowLeft":
        loadPreviousImage();
        break;
      case "KeyH":
        loadPreviousImage();
        break;
      case "Space":
        if (isFavorite(_activeImage.value.id)) {
          removeFromFavorites(_activeImage.value.id);
        } else {
          markAsFavorite(_activeImage.value.id);
        }
        break;
    }
  }
}

function onKeyDown(e: KeyboardEvent) {
  if (e.isComposing || e.keyCode === 229) {
    return;
  }

  switch (e.code) {
    case "Space":
      e.preventDefault();
      break;
  }
}

function setActivePoseId(id: PoseId) {
  changeContextToDefault();

  if (_fullListOfRandomPosesIds.value && _fullListOfRandomPosesIds.value.includes(id)) {
    _activePoseId.value = id;
    _activePosesListIndex = _fullListOfRandomPosesIds.value.indexOf(id);

    return true;
  }

  return false;
}

function getPoseIdForSharing() {
  return _activeImage.value ? _activeImage.value.hash : "";
}

function decodePoseSharedLinkToPoseId(hash: string) {
  const pose = allPoses.find((pose) => pose.hash == hash);

  if (pose) {
    return pose.id;
  }

  // eslint-disable-next-line
  console.error(new Error("No pose found with this hash."));
}

function saveSharedPoseId(poseId: PoseId) {
  localStorage.setItem(LS_APP_SHARED_POSE_ID, poseId.toString());
}

function getSavedSharePoseId(): PoseId | null {
  const id = localStorage.getItem(LS_APP_SHARED_POSE_ID);

  if (id) {
    return id as PoseId;
  } else {
    return null;
  }
}

function clearSavedSharePose() {
  localStorage.removeItem(LS_APP_SHARED_POSE_ID);
}

export default function () {
  if (!allPoses.length) {
    allPoses = Poses.concat(PremiumPoses());
  }

  if (!_fullListOfRandomPosesIds.value) {
    _fullListOfRandomPosesIds.value = shuffleList(allPoses.map((pose) => pose.id));
  }

  if (!_activePoseId.value) {
    _activePoseId.value = _fullListOfRandomPosesIds.value[_activePosesListIndex];
  }

  if (!_activeImage.value) {
    _activeImage.value = findPoseById(_activePoseId.value);
  }

  if (!_favoritesListenerRegister) {
    try {
      onFavoritePoseChange((poses: PoseId[]) => {
        _favoritesPosesIdsList.value = poses || [];
      });

      _favoritesListenerRegister = true;
    } catch (e) {
      _favoritesPosesIdsList.value = [];
    }
  }

  return {
    getPoseIdForSharing,
    decodePoseSharedLinkToPoseId,
    setActivePoseId,
    onKeyUp,
    onKeyDown,
    loadNextImage,
    loadPreviousImage,
    isTouchDisabled: computed(() => _isActionsDisabled),
    activeImage: computed(() => _activeImage.value),
    markAsFavorite,
    removeFromFavorites,
    isFavorite,
    saveSharedPoseId,
    clearSavedSharePose,
    getSavedSharePoseId,
    changeContextToDefault,
    changeContextToFavorites,
    changeContextToCategory,
    setAnimationState,
    AnimationState,
    Context,
    context: computed(() => _activeContext.value),
    movableLength: computed(() => (window.innerWidth * _canTranslateProc) / 100),
    animationTransitions: computed(() => `transform ${_animationSpeed / 1000}s, opacity ${_animationSpeed / 1000}s`),
    activeAnimationState: computed(() => _animationState.value),
    allFavoritesPoses: computed(() => allPoses.filter((pose) => _favoritesPosesIdsList.value.includes(pose.id))),
  };
}
