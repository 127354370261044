import { Config, ConfigTexts } from "@/types/config";

interface CategoriesConfigTexts extends ConfigTexts {
  title: string;
}

interface CategoriesConfig extends Config {
  texts: CategoriesConfigTexts;
}

const texts: CategoriesConfigTexts = {
  title: "Categories",
};

const config: CategoriesConfig = {
  texts,
};

export default config;
