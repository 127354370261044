
import { defineComponent } from "vue";

import useAuth from "@/composibles/use-auth";
import MySubscriptionConfig from "@/config/my-subscription-page";

export const name = "invited-partner-box";

export const component = defineComponent({
  name,
  setup() {
    const { invitedByEmail } = useAuth();

    return {
      invitedByEmail,
      invitedByText: MySubscriptionConfig.texts.invitedByText,
    };
  },
});

export default component;
