import { Pose } from "@/config/app-page";
import { CategoryId } from "@/config/_app-page/_categories";
import { PoseId } from "@/config/_app-page/_pose-id";
import { Poses } from "@/config/_app-page/_poses";
import { PremiumPoses } from "@/config/_app-page/_premium-poses";

export default function () {
  const premiumPoses = PremiumPoses();
  const AllPoses = Poses.concat(premiumPoses);

  function getPosesByCategory(categoryId: CategoryId): Pose[] {
    return AllPoses.filter((pose) => pose.categoriesIds.includes(categoryId));
  }

  function getPoseById(id: PoseId): Pose {
    return AllPoses.find((pose) => pose.id == id) as Pose;
  }

  function isCategoryHasAnyPoses(categoryId: CategoryId) {
    return !!AllPoses.find((pose) => pose.categoriesIds.includes(categoryId));
  }

  function isCategoryHasOnlyPremiumPoses(categoryId: CategoryId) {
    return !!Poses.find((pose) => pose.categoriesIds.includes(categoryId)) === false;
  }

  return {
    //States
    premiumPoses,

    // Methods
    getPoseById,
    getPosesByCategory,
    isCategoryHasOnlyPremiumPoses,
    isCategoryHasAnyPoses,
  };
}
