
import { defineComponent, onMounted } from "vue";
import useBackground from "@/composibles/use-background";
import AboutUsConfig from "@/config/about-us-page";
import { getMainAssetAsBase64, getPoseAssetAsBase64 } from "@/utils/assets";
import useTailwind from "@/composibles/use-tailwind";
import { color } from "@/config/tailwind";

export const name = "about-us-view";

export const component = defineComponent({
  name,
  setup() {
    const { isMobile } = useTailwind();

    onMounted(() => {
      useBackground().changeBgColorClass(color.white);
    });

    const wave1Style = `background-image: url('${getMainAssetAsBase64("about-us/wave-1.svg")}')`;
    const circle1Style = `background-image: url('${getMainAssetAsBase64("about-us/circle-1.svg")}')`;
    const circle2Style = `background-image: url('${getMainAssetAsBase64("about-us/circle-2.svg")}')`;
    const circle3Style = `background-image: url('${getMainAssetAsBase64("about-us/circle-3.svg")}')`;
    const circle4Style = `background-image: url('${getMainAssetAsBase64("about-us/circle-4.svg")}')`;
    const circle5Style = `background-image: url('${getMainAssetAsBase64("about-us/circle-5.svg")}')`;
    const circle6Style = `background-image: url('${getMainAssetAsBase64("about-us/circle-6.svg")}')`;
    const circle7Style = `background-image: url('${getMainAssetAsBase64("about-us/circle-7.svg")}')`;
    const circle8Style = `background-image: url('${getMainAssetAsBase64("about-us/circle-8.svg")}')`;
    const pose1Style = `background-image: url('${getPoseAssetAsBase64("62057_kneel.svg")}')`;
    const pose2Style = `background-image: url('${getPoseAssetAsBase64("62079_lotus.svg")}')`;

    return {
      texts: AboutUsConfig.texts,
      isMobile,

      // Assets
      wave1Style,
      circle1Style,
      circle2Style,
      circle3Style,
      circle4Style,
      circle5Style,
      circle6Style,
      circle7Style,
      circle8Style,
      pose1Style,
      pose2Style,
    };
  },
});

export default component;
