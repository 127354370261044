import { RouteRecordRaw } from "vue-router";
import { routeName } from "@/config/router";
import ListLayout from "@/layouts/list-layout/list-layout.vue";
import FavoritesPosesListView from "./views/favorites-poses-list-view.vue";
import useLogin from "@/composibles/use-login";
import { computed } from "vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/favorites",
    component: ListLayout,
    children: [
      {
        name: routeName.favorites,
        path: "",
        component: FavoritesPosesListView,
        meta: {
          isValidForUser: [
            {
              valid: computed(() => useLogin().isUserLoggedIn.value),
              fallbackUrl: routeName.loginWithEmailLink,
            },
          ],
        },
      },
    ],
  },
];

export default routes;
