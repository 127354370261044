import { Config, ConfigTexts } from "@/types/config";

interface ContactUsCard {
  title: string;
  description: string;
  link: string;
  linkText: string;
}

interface ContactUsConfigTexts extends ConfigTexts {
  title: string;
  description: string;
  cards: ContactUsCard[];
}

interface ContactUsConfig extends Config {
  texts: ContactUsConfigTexts;
}

const title = "Contact us";

const texts: ContactUsConfigTexts = {
  title,
  description: `Got something you want to talk about? Contact us or email us and we promise to get back to you as soon as we can.`,
  cards: [
    {
      title: `HELP / SUPPORT`,
      description: `For all technical and app-related questions`,
      link: "mailto:help@clicy.xyz",
      linkText: "help@clicy.xyz",
    },
    {
      title: `PARTNERSHIPS`,
      description: `Interested in partnering with Clicy?`,
      link: "mailto:partners@clicy.xyz",
      linkText: "partners@clicy.xyz",
    },
    {
      title: `COMMUNITY GROUP`,
      description: `Join the Clicy community group at`,
      link: "https://t.me/clicy",
      linkText: "Telegram",
    },
  ],
};

const config: ContactUsConfig = {
  texts,
};

export default config;
