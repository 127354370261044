import { Config, ConfigTexts } from "@/types/config";

interface LandingConfigTexts extends ConfigTexts {
  title: string;
  welcomeMessage: string;
  loginBtnText: string;
  playBtnText: string;
  description: string;
}

interface LandingConfig extends Config {
  texts: LandingConfigTexts;
}

const texts: LandingConfigTexts = {
  title: "Home",
  welcomeMessage: "Hi there",
  loginBtnText: "LOG IN",
  playBtnText: "Let's Play",
  description: `Treat Clicy as a sex-related library and use it to expand your sexual horizons and have fun experimenting!`,
};

const config: LandingConfig = {
  texts,
};

export default config;
