
import { defineComponent } from "vue";

export const name = "page-title";

export const component = defineComponent({
  name,
  props: {
    title: {
      type: String,
      required: true,
    },
    addTitleBottomBorder: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
});

export default component;
