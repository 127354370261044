
import { defineComponent, onMounted } from "vue";
import useBackground from "@/composibles/use-background";
import ContactUsConfig from "@/config/contact-us-page";
import { getMainAssetAsBase64 } from "@/utils/assets";
import { color } from "@/config/tailwind";

export const name = "contact-us-view";

export const component = defineComponent({
  name,
  setup() {
    onMounted(() => {
      useBackground().changeBgColorClass(color.white);
    });

    const circle1Style = `background-image: url('${getMainAssetAsBase64("contact-us/circle-1.svg")}')`;
    const circle2Style = `background-image: url('${getMainAssetAsBase64("contact-us/circle-2.svg")}')`;
    const circle3Style = `background-image: url('${getMainAssetAsBase64("contact-us/circle-3.svg")}')`;

    return {
      texts: ContactUsConfig.texts,

      // Assets
      circle1Style,
      circle2Style,
      circle3Style,
    };
  },
});

export default component;
