import { color } from "../tailwind";
import { getPremiumPoseAssetAsBase64 } from "@/utils/assets";
import hash from "object-hash";
import { Pose } from "@/config/app-page";
import { PoseId } from "./_pose-id";
import { CategoryId } from "./_categories";
import usePremium from "@/composibles/use-premium";

export function PremiumPoses(): Pose[] {
  const { activePlan } = usePremium();

  if (!activePlan.enabled.value) {
    return [];
  }

  return [
    {
      id: PoseId.upstandingCitizen,
      title: `Upstanding Citizen`,
      description: `She straddles him, wrapping her legs around his body as he keeps his knees slightly loose and legs lightly spread. He stands and holds her in his arms. You can start on the bed and have him pick her up without disengaging or (be careful) she can just jump on him while standing.`,
      imageName: "62036_upstanding_citizen.svg",
      backgroundColor: color.blue400,
      categoriesIds: [CategoryId.Standing],
    },
    {
      id: PoseId.pretzelDip,
      title: `Pretzel Dip`,
      description: `She lies slightly on the side, he kneels straddling her leg and curling another leg around his side. This position allows for deeper penetration in a partial doggy style while still being able to maintain eye contact. If the bed is jumpy he could try to add up-down movement as well thus providing more frictions for the inner walls.`,
      imageName: "62039_pretzel_dip.svg",
      backgroundColor: color.blue400,
      categoriesIds: [CategoryId.HeOnTop, CategoryId.FaceToFace],
    },
    {
      id: PoseId.eiffelTower,
      title: `Eiffel Tower`,
      description: `She stands with her legs a little wider than the shoulder-width then bends forwards at the waist placing palms on the floor. If she is flexible enough she could put elbows on the floor for a better angle. He stands behind and bends the knees slightly. From this position both anus or vagina penetration is available. He then firmly holds her hips or buttocks while thrusting to increase stability and intimacy. This position allows for deep penetration and G-spot or P-spot stimulation.`,
      imageName: "62040_eiffel_tower.svg",
      backgroundColor: color.red300,
      categoriesIds: [CategoryId.Standing, CategoryId.Anal],
    },
    {
      id: PoseId.veledictorian,
      title: `Veledictorian`,
      description: `She lies on her back and raises her legs while extending them straight out all the way up to form a "V" and tilts ankles as close to the head as possible. He enters from a missionary position with his hands near her neck. This position allows good body contact and deeper penetration while her hands are free to proceed with clitoris stimulation.`,
      imageName: "62041_veledictorian.svg",
      backgroundColor: color.gray300,
      categoriesIds: [CategoryId.HeOnTop],
    },
    {
      id: PoseId.eagle,
      title: `Eagle`,
      description: ``,
      imageName: "62042_eagle.svg",
      backgroundColor: color.red300,
      categoriesIds: [CategoryId.HeOnTop, CategoryId.FaceToFace],
    },
    {
      id: PoseId.spreadEagle,
      title: `Spread Eagle`,
      description: ``,
      imageName: "62044_spread_eagle.svg",
      backgroundColor: color.red300,
      categoriesIds: [CategoryId.FaceToFace, CategoryId.HeOnTop],
    },
    {
      id: PoseId.casket,
      title: `Casket`,
      description: `She sits with her arms behind her, putting the weight in her palms. He gets on one knee between her spread legs as she puts her calves on his shoulders lifting the bottom half off the ground. This is a more advanced and challenging position that offers an opportunity for face-to-face interactions.`,
      imageName: "62045_casket.svg",
      backgroundColor: color.gray300,
      categoriesIds: [CategoryId.FaceToFace, CategoryId.Kneeling],
    },
    {
      id: PoseId.reverseCowboy,
      title: `Reverse Cowboy`,
      description: ``,
      imageName: "62049_reverse_cowboy.svg",
      backgroundColor: color.red300,
      categoriesIds: [CategoryId.RearEntry, CategoryId.Acrobatic],
    },
    {
      id: PoseId.merger,
      title: `Merger`,
      description: ``,
      imageName: "62051_merger.svg",
      backgroundColor: color.blue400,
      categoriesIds: [CategoryId.Seated, CategoryId.FaceToFace],
    },
    {
      id: PoseId.balletDancer,
      title: `Ballet Dancer`,
      description: ``,
      imageName: "62052_ballet_dancer.svg",
      backgroundColor: color.red300,
      categoriesIds: [CategoryId.Standing, CategoryId.FaceToFace],
    },
    {
      id: PoseId.seatedWheelbarrow,
      title: `Seated Wheelbarrow`,
      description: ``,
      imageName: "62053_seated_wheelbarrow.svg",
      backgroundColor: color.blue400,
      categoriesIds: [CategoryId.Seated, CategoryId.Anal],
    },
    {
      id: PoseId.leopard,
      title: `Leopard`,
      description: ``,
      imageName: "62055_leopard.svg",
      backgroundColor: color.red300,
      categoriesIds: [CategoryId.Anal, CategoryId.Kneeling],
    },
    {
      id: PoseId.amazon,
      title: `Amazon`,
      description: ``,
      imageName: "62056_amazon.svg",
      backgroundColor: color.gray300,
      categoriesIds: [CategoryId.SheOnTop, CategoryId.Kneeling],
    },
    {
      id: PoseId.kneel,
      title: `Kneel`,
      description: ``,
      imageName: "62057_kneel.svg",
      backgroundColor: color.blue400,
      categoriesIds: [CategoryId.Kneeling, CategoryId.FaceToFace],
    },
    {
      id: PoseId.dolphin,
      title: `Dolphin`,
      description: ``,
      imageName: "62058_dolphin.svg",
      backgroundColor: color.red300,
      categoriesIds: [CategoryId.Acrobatic, CategoryId.Anal],
    },
    {
      id: PoseId.necking,
      title: `Necking`,
      description: ``,
      imageName: "62059_necking.svg",
      backgroundColor: color.gray300,
      categoriesIds: [CategoryId.Kneeling, CategoryId.RearEntry],
    },
    {
      id: PoseId.screwdriverSexPosition,
      title: `Screwdriver Sex Position`,
      description: ``,
      imageName: "62060_screwdriver_sex_position.svg",
      backgroundColor: color.blue400,
      categoriesIds: [CategoryId.RearEntry, CategoryId.HeOnTop],
    },
    {
      id: PoseId.butterChurner,
      title: `Butter Churner`,
      description: ``,
      imageName: "62062_butter_churner.svg",
      backgroundColor: color.gray300,
      categoriesIds: [CategoryId.Acrobatic, CategoryId.Anal],
    },
    {
      id: PoseId.pole,
      title: `Pole`,
      description: ``,
      imageName: "62068_pole.svg",
      backgroundColor: color.gray300,
      categoriesIds: [CategoryId.SheOnTop, CategoryId.Anal],
    },
    {
      id: PoseId.armlock,
      title: `Armlock`,
      description: ``,
      imageName: "62069_armlock.svg",
      backgroundColor: color.blue400,
      categoriesIds: [CategoryId.HeOnTop, CategoryId.RearEntry],
    },
    {
      id: PoseId.theHero,
      title: `The Hero`,
      description: ``,
      imageName: "62070_the_hero.svg",
      backgroundColor: color.red300,
      categoriesIds: [CategoryId.FaceToFace, CategoryId.HeOnTop],
    },
    {
      id: PoseId.turtle,
      title: `Turtle`,
      description: ``,
      imageName: "62071_turtle.svg",
      backgroundColor: color.gray300,
      categoriesIds: [CategoryId.Kneeling, CategoryId.Anal],
    },
    {
      id: PoseId.narcissus,
      title: `Narcissus`,
      description: ``,
      imageName: "62072_narcissus.svg",
      backgroundColor: color.blue400,
      categoriesIds: [CategoryId.Standing, CategoryId.Acrobatic],
    },
    {
      id: PoseId.starfish,
      title: `Starfish`,
      description: ``,
      imageName: "62073_starfish.svg",
      backgroundColor: color.red300,
      categoriesIds: [CategoryId.SheOnTop, CategoryId.Acrobatic],
    },
    {
      id: PoseId.rainbow,
      title: `Rainbow`,
      description: ``,
      imageName: "62074_rainbow.svg",
      backgroundColor: color.gray300,
      categoriesIds: [CategoryId.HeOnTop, CategoryId.Anal],
    },
    {
      id: PoseId.rabbitEars,
      title: `Rabbit Ears`,
      description: ``,
      imageName: "62075_rabbit_ears.svg",
      backgroundColor: color.blue400,
      categoriesIds: [CategoryId.Acrobatic],
    },
    {
      id: PoseId.wheelBarrow,
      title: `Wheel Barrow`,
      description: ``,
      imageName: "62076_wheel_barrow.svg",
      backgroundColor: color.red300,
      categoriesIds: [CategoryId.Acrobatic, CategoryId.RearEntry],
    },
    {
      id: PoseId.spider,
      title: `Spider`,
      description: ``,
      imageName: "62063_spider.svg",
      backgroundColor: color.blue400,
      categoriesIds: [CategoryId.FaceToFace, CategoryId.Acrobatic],
    },
    {
      id: PoseId.wildPony,
      title: `Wild Pony`,
      description: ``,
      imageName: "62085_wild_pony.svg",
      backgroundColor: color.red300,
      categoriesIds: [CategoryId.Acrobatic, CategoryId.RearEntry],
    },
    {
      id: PoseId.boat,
      title: `Boat`,
      description: ``,
      imageName: "62061_boat.svg",
      backgroundColor: color.red300,
      categoriesIds: [CategoryId.LyingDown, CategoryId.Anal],
    },
    {
      id: PoseId.classic69,
      title: `Classic 69`,
      description: `He lies down on his back while she lies on top of him with her knees straddling his head. Then oral sex is being performed on one another. He should use pillows to support his neck if he is struggling to reach or finds himself straining his neck.`,
      imageName: "62047_classic_69.svg",
      backgroundColor: color.red300,
      categoriesIds: [CategoryId.Oral, CategoryId.LyingDown],
    },
    {
      id: PoseId.inverted69,
      title: `Inverted 69`,
      description: `She lies on her back while he places his knees just above her head and lies on his elbows, avoids putting all of the weight on her. Then the oral sex is performed. This is a more rough experience than a classical 69 as it allows him to go harder and deeper down her throat so he should be more aware of his movements - allow her to set her pace and have enough space to retract in case a shortage of air will become a problem. `,
      imageName: "62048_inverted_69.svg",
      backgroundColor: color.gray300,
      categoriesIds: [CategoryId.Oral, CategoryId.LyingDown],
    },
    {
      id: PoseId.bloomingOrchid,
      title: `Blooming Orchid`,
      description: ``,
      imageName: "62078_blooming_orchid.svg",
      backgroundColor: color.blue400,
      categoriesIds: [CategoryId.Kneeling, CategoryId.FaceToFace],
    },
    {
      id: PoseId.lotus,
      title: `Lotus`,
      description: ``,
      imageName: "62079_lotus.svg",
      backgroundColor: color.red300,
      categoriesIds: [CategoryId.FaceToFace, CategoryId.Seated],
    },
    {
      id: PoseId.highwayToHeaven,
      title: `Highway To Heaven`,
      description: ``,
      imageName: "62080_highway_to_heaven.svg",
      backgroundColor: color.gray300,
      categoriesIds: [CategoryId.Seated, CategoryId.Kneeling],
    },
    {
      id: PoseId.lapDance,
      title: `Lap Dance`,
      description: ``,
      imageName: "62081_lap_dance.svg",
      backgroundColor: color.blue400,
      categoriesIds: [CategoryId.Seated, CategoryId.SheOnTop],
    },
    {
      id: PoseId.throne,
      title: `Throne`,
      description: ``,
      imageName: "62082_throne.svg",
      backgroundColor: color.red300,
      categoriesIds: [CategoryId.Seated, CategoryId.FaceToFace],
    },
    {
      id: PoseId.antiGravityBall,
      title: `Anti-Gravity Ball`,
      description: ``,
      imageName: "62084_anti-gravity_ball.svg",
      backgroundColor: color.blue400,
      categoriesIds: [CategoryId.Acrobatic, CategoryId.Oral],
    },
    {
      id: PoseId.sevenAndOne,
      title: `Seven And One`,
      description: ``,
      imageName: "62086_seven_and_one.svg",
      backgroundColor: color.gray300,
      categoriesIds: [CategoryId.NonPenetrative],
    },
    {
      id: PoseId.handWashCycle,
      title: `Hand Wash Cycle`,
      description: ``,
      imageName: "62087_hand_wash_cycle.svg",
      backgroundColor: color.blue400,
      categoriesIds: [CategoryId.NonPenetrative],
    },
    {
      id: PoseId.footLocker,
      title: `Foot Locker`,
      description: ``,
      imageName: "62088_foot_locker.svg",
      backgroundColor: color.red300,
      categoriesIds: [CategoryId.NonPenetrative],
    },
    {
      id: PoseId.cliffHanger,
      title: `Cliff Hanger`,
      description: ``,
      imageName: "62089_cliff_hanger.svg",
      backgroundColor: color.gray300,
      categoriesIds: [CategoryId.Oral],
    },
    {
      id: PoseId.sidecar,
      title: `Sidecar`,
      description: ``,
      imageName: "62090_sidecar.svg",
      backgroundColor: color.blue400,
      categoriesIds: [CategoryId.Oral],
    },
    {
      id: PoseId.midfieldSack,
      title: `Midfield Sack`,
      description: ``,
      imageName: "62091_midfield_sack.svg",
      backgroundColor: color.red300,
      categoriesIds: [CategoryId.RearEntry, CategoryId.Kneeling],
    },
    {
      id: PoseId.wheelbarrow2,
      title: `Wheelbarrow`,
      description: ``,
      imageName: "62092_wheelbarrow.svg",
      backgroundColor: color.gray300,
      categoriesIds: [CategoryId.Standing, CategoryId.RearEntry],
    },
    {
      id: PoseId.superman,
      title: `Superman`,
      description: ``,
      imageName: "62093_superman.svg",
      backgroundColor: color.blue400,
      categoriesIds: [CategoryId.Standing, CategoryId.Acrobatic],
    },
    {
      id: PoseId.mermaid,
      title: `Mermaid`,
      description: ``,
      imageName: "62094_mermaid.svg",
      backgroundColor: color.red300,
      categoriesIds: [CategoryId.Acrobatic, CategoryId.SheOnTop],
    },
    {
      id: PoseId.waterfall,
      title: `Waterfall`,
      description: ``,
      imageName: "62095_waterfall.svg",
      backgroundColor: color.gray300,
      categoriesIds: [CategoryId.Acrobatic, CategoryId.Kneeling],
    },
    {
      id: PoseId.fireHydrant,
      title: `Fire Hydrant`,
      description: ``,
      imageName: "62096_fire_hydrant.svg",
      backgroundColor: color.blue400,
      categoriesIds: [CategoryId.Oral],
    },
    {
      id: PoseId.happyHands,
      title: `Happy Hands`,
      description: ``,
      imageName: "62097_happy_hands.svg",
      backgroundColor: color.red300,
      categoriesIds: [CategoryId.NonPenetrative],
    },
  ].map((pose) => ({
    ...pose,
    hash: hash(pose.id),
    imageContent: () => getPremiumPoseAssetAsBase64("poses/" + pose.imageName),
  }));
}
