import { ref, computed } from "vue";

const _breadcrumbs = ref([] as string[]);

function setBreadcrumbs(breadcrumbs: string[]) {
  _breadcrumbs.value = breadcrumbs;
}

export default function () {
  return {
    setBreadcrumbs,
    breadcrumbs: computed(() => _breadcrumbs.value),
  };
}
