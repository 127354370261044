/**
 *
 * @param min Minimum number - inclusive
 * @param max Maximum number - exclusive
 */
export function getRandomInt(min: number, exclusiveMax: number): number {
  min = Math.ceil(min);
  exclusiveMax = Math.floor(exclusiveMax);

  return Math.floor(Math.random() * (exclusiveMax - min)) + min;
}

/**
 *
 * @param min Minimum number - inclusive
 * @param max Maximum number - exclusive
 * @param except This number will be skipped
 */
export function getRandomIntWithException(min: number, exclusiveMax: number, except: null | number = null): number {
  const random = getRandomInt(min, exclusiveMax);

  // Skip number if except number defined and is equal witch random number
  if (except && random === except) {
    return getRandomIntWithException(min, exclusiveMax, except);
  }

  return random;
}

/**
 *  Return shuffled array
 *
 * @param list any array
 */
export function shuffleList<T>(list: T[]): T[] {
  const _list = [...list];
  let shuffledList = [] as T[];

  while (_list.length) {
    shuffledList = shuffledList.concat(_list.splice(getRandomInt(0, _list.length), 1));
  }

  return shuffledList;
}
