import { Category } from "../app-page";
import { routeName } from "../router";
import { getPoseAssetAsBase64 } from "@/utils/assets";

export enum CategoryId {
  LyingDown,
  FaceToFace,
  RearEntry,
  SheOnTop,
  HeOnTop,
  Standing,
  Kneeling,
  Seated,
  Oral,
  Anal,
  Acrobatic,
  NonPenetrative,
}
interface HiddenCategory {
  title: string;
  thumbnailImageName: string;
  // thumbnail: () => string;
}

const HiddenCategory: HiddenCategory = {
  title: "SUBSCRIBE TO UNLOCK",
  thumbnailImageName: "62037_reverse_cowgirl.svg",
  // thumbnail: () => getPoseAssetAsBase64(""),
};

export const Categories: Category[] = [
  {
    id: CategoryId.LyingDown,
    title: "Lying Down",
    thumbnail: null,
    thumbnailImageName: "62061_boat.svg",
    toCategoryPosesListRoute: null,
  },
  {
    id: CategoryId.FaceToFace,
    title: "Face To Face",
    thumbnail: null,
    thumbnailImageName: "62050_gemini.svg",
    toCategoryPosesListRoute: null,
  },
  {
    id: CategoryId.RearEntry,
    title: "Rear Entry",
    thumbnail: null,
    thumbnailImageName: "62060_screwdriver_sex_position.svg",
    toCategoryPosesListRoute: null,
  },
  {
    id: CategoryId.SheOnTop,
    title: "She On Top",
    thumbnail: null,
    thumbnailImageName: "62046_italian_chandelier.svg",
    toCategoryPosesListRoute: null,
  },
  {
    id: CategoryId.HeOnTop,
    title: "He On Top",
    thumbnail: null,
    thumbnailImageName: "62041_veledictorian.svg",
    toCategoryPosesListRoute: null,
  },
  {
    id: CategoryId.Standing,
    title: "Standing",
    thumbnail: null,
    thumbnailImageName: "62040_eiffel_tower.svg",
    toCategoryPosesListRoute: null,
  },
  {
    id: CategoryId.Kneeling,
    title: "Kneeling",
    thumbnail: null,
    thumbnailImageName: "62057_kneel.svg",
    toCategoryPosesListRoute: null,
  },
  {
    id: CategoryId.Seated,
    title: "Seated",
    thumbnail: null,
    thumbnailImageName: "62051_merger.svg",
    toCategoryPosesListRoute: null,
  },
  {
    id: CategoryId.Oral,
    title: "Oral",
    thumbnail: null,
    thumbnailImageName: "62047_classic_69.svg",
    toCategoryPosesListRoute: null,
  },
  {
    id: CategoryId.Anal,
    title: "Anal",
    thumbnail: null,
    thumbnailImageName: "62043_concubine.svg",
    toCategoryPosesListRoute: null,
  },
  {
    id: CategoryId.Acrobatic,
    title: "Acrobatic",
    thumbnail: null,
    thumbnailImageName: "62076_wheel_barrow.svg",
    toCategoryPosesListRoute: null,
  },
  {
    id: CategoryId.NonPenetrative,
    title: "Non Penetrative",
    thumbnail: null,
    thumbnailImageName: "62088_foot_locker.svg",
    toCategoryPosesListRoute: null,
  },
].map((category) => {
  // Format RawLocation object for router
  return {
    ...category,
    // thumbnail: () => getPoseAssetAsBase64(category.thumbnailImageName),
    toCategoryPosesListRoute: {
      name: routeName.categoryPosesList,
      params: {
        CategorySlug: category.title.split(" ").join("-").toLowerCase(),
      },
    },
  };
});

export { HiddenCategory };
