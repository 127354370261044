
import { defineComponent, computed, reactive, ComputedRef } from "vue";

import { color } from "@/config/tailwind";
import { getPoseAssetAsBase64 } from "@/utils/assets";

// Composibles
import useBackground from "@/composibles/use-background";
import usePoses from "@/composibles/use-poses";
import usePremium from "@/composibles/use-premium";
import useAuth from "@/composibles/use-auth";

// Components
import InvitedPartnerBox from "../components/invited-partner-box.vue";
import InvitePartnerForm from "../components/invite-partner-form.vue";
import EditSubscriptionForm from "../components/edit-subscription-form.vue";
import RevokePartnerAccessForm from "../components/revoke-partner-access-form.vue";

import config from "@/config/my-subscription-page";

export const name = "my-subscription-view";

export const component = defineComponent({
  name,
  components: {
    InvitedPartnerBox,
    InvitePartnerForm,
    EditSubscriptionForm,
    RevokePartnerAccessForm,
  },
  setup() {
    const { getPoseById } = usePoses();
    const { activePlan } = usePremium();
    const { invitedPartnerEmail, nextInvoice, cancelAtPeriodEnd } = useAuth();

    useBackground().changeBgColorClass(color.white);

    return {
      getPoseAssetAsBase64,
      activePlan: reactive(activePlan),
      poseSrcName: computed(() => getPoseById(activePlan.poseId.value).imageName),
      invitedPartnerEmail,

      // TODO Valid till
      validTill: computed(() => (cancelAtPeriodEnd.value ? config.texts.subscriptionEnds : config.texts.nextInvoice)),
      subscriptionValidTill: nextInvoice as ComputedRef<number>,
    };
  },
});

export default component;
