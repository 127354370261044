
import { defineComponent } from "vue";
import useApp from "@/composibles/use-app";
import ShareButton from "@/components/share-button.vue";
import useTailwind from "@/composibles/use-tailwind";

export const name = "app-actions";

export const component = defineComponent({
  name,
  components: { ShareButton },
  setup() {
    const { isMobile } = useTailwind();
    const { activeImage, loadPreviousImage, loadNextImage, markAsFavorite, removeFromFavorites, isFavorite } = useApp();

    return {
      activeImage,
      loadPreviousImage,
      loadNextImage,
      markAsFavorite,
      removeFromFavorites,
      isFavorite,
      isMobile,
    };
  },
});

export default component;
