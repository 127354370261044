/**
 * Ad blocker popup texts
 */
const _AdBlockerPopupTexts = {
  title: "Please disable your ad blocker",
  content:
    "Unfortunately Clicy app cannot provide full experience with ad blocker enabled. Please consider disabling it to proceed.",
};

/**
 * Global texts
 */
export const Text = {
  AdBlockPopup: _AdBlockerPopupTexts,
};
