import { routeName } from "@/config/router";
import { computed, ref, watch } from "vue";
import { Router, useRouter } from "vue-router";

enum LogoColor {
  "white",
  "red",
  "colored",
}

let _router: Router;
const _LogoColorState = ref<LogoColor>();

function _getLogoColor() {
  const whiteLogoRouteNames = [routeName.app];
  const redLogoRouteNames = [routeName.categories, routeName.categoryPosesList, routeName.favorites];

  if (_router && _router.currentRoute.value.name) {
    const name = _router.currentRoute.value.name as routeName;

    if (name) {
      if (whiteLogoRouteNames.includes(name)) {
        return LogoColor.white;
      } else if (redLogoRouteNames.includes(name)) {
        return LogoColor.red;
      }
    }
  } else {
    return LogoColor.colored;
  }
}

function _refreshLogoColorState() {
  _LogoColorState.value = _getLogoColor();
}

export default function () {
  _router = useRouter();
  _refreshLogoColorState();

  watch(_router.currentRoute, _refreshLogoColorState);

  return {
    // Enums
    LogoColor,

    // Vars
    LogoColorState: computed(() => _LogoColorState.value),

    // Mutations
  };
}
