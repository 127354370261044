import { ref, computed, defineComponent } from "vue";
import { routeName } from "@/config/router";
import { RouteRecordRaw } from "vue-router";
import useLogin from "./use-login";

const _showCopiedBoxPopUpInSeconds = 3;
const _poseShareUrlPrefix = "app/share/pose";
const _localStorageKeyViewableLink = "PoseShare-view-after-terms-confirmed";
const _isCopyToClipboardVisible = ref(false);

const isCopyToClipboardVisible = computed(() => _isCopyToClipboardVisible.value);

// Remove any old saves
localStorage.removeItem(_localStorageKeyViewableLink);

function copyPoseUrl(url = "https://clicy.app") {
  if (!_isCopyToClipboardVisible.value) {
    const elId = "share-pose-url";
    const el = document.createElement("input");

    el.setAttribute("style", "position: absolute; top: -100%; left: -100%");
    el.setAttribute("id", elId);
    el.value = url;

    document.body.appendChild(el);

    el.select();

    document.execCommand("copy");

    document.body.removeChild(el);

    _isCopyToClipboardVisible.value = true;

    // Hide popup after seconds
    setTimeout(() => (_isCopyToClipboardVisible.value = false), _showCopiedBoxPopUpInSeconds * 1000);
  }
}

function setPoseIdForLaterView(id: string) {
  localStorage.setItem(_localStorageKeyViewableLink, id);
}

function isViewablePoseLink() {
  return !!localStorage.getItem(_localStorageKeyViewableLink);
}

function getViewablePoseId() {
  return localStorage.getItem(_localStorageKeyViewableLink);
}

function getPoseShareRoute() {
  const route: RouteRecordRaw = {
    name: routeName.appShortCut,
    path: `/${_poseShareUrlPrefix}/:id`,
    component: defineComponent({}),
    meta: {
      isValidForUser: [
        {
          valid: useLogin().isUserLoggedIn,
          fallbackUrl: routeName.loginWithEmailLink,
        },
      ],
      redirectToOnValid: routeName.app,
    },
  };

  return route;
}
function getPoseShareUrl(origin: string, id: string) {
  return `${origin}/${_poseShareUrlPrefix}/${id}`;
}

export default function () {
  return {
    copyPoseUrl,
    getPoseShareUrl,
    getPoseShareRoute,
    setPoseIdForLaterView,
    getViewablePoseId,
    isViewablePoseLink,
    isCopyToClipboardVisible,
  };
}
