
import { defineComponent, onMounted, ref } from "vue";
import useBackground from "@/composibles/use-background";
import { getMainAssetAsBase64 } from "@/utils/assets";
import useTailwind from "@/composibles/use-tailwind";
import useLogin from "@/composibles/use-login";
import LoginConfig from "@/config/login-page";
import { routeName } from "@/config/router";
import { color } from "@/config/tailwind";

export const name = "login-get-code-view";

export const component = defineComponent({
  name,
  setup() {
    const isLoading = ref(false);
    const { isMobile } = useTailwind();
    const { isEmailValid, loginSubmit, email, isEmailSent, isSubmitDisabled, loginError } = useLogin();

    onMounted(() => useBackground().changeBgColorClass(color.gray200));

    const wave1Style = `background-image: url("${getMainAssetAsBase64("login/get-code/wave-1.svg")}");`;
    const circle1Style = `background-image: url("${getMainAssetAsBase64("login/get-code/circle-1.svg")}");`;
    const circle2Style = `background-image: url("${getMainAssetAsBase64("login/get-code/circle-2.svg")}");`;
    const circle3Style = `background-image: url("${getMainAssetAsBase64("login/get-code/circle-3.svg")}");`;

    return {
      texts: LoginConfig.texts,
      isMobile,
      routeName,
      loginSubmit: async (e: Event) => {
        isLoading.value = true;

        await loginSubmit(e);
        isLoading.value = false;
      },
      email,
      isEmailValid,
      isEmailSent,
      isSubmitDisabled,
      loginError,
      isLoading,

      // Assets
      wave1Style,
      circle1Style,
      circle2Style,
      circle3Style,
    };
  },
});

export default component;
