import { HamburgerMenuContextLevel, TopBarMenuItem } from "@/composibles/top-bar/_use-menu";
import { ModuleIndexForTopBarMenuList } from "@/config";
import { Module } from "@/types/module";
import routes from "./contact-us-router";
import ContactUsConfig from "@/config/contact-us-page";
import { routeName } from "@/config/router";
import { computed } from "vue";

const topMenuMenuItems: TopBarMenuItem[] = [
  {
    menuItemIndex: ModuleIndexForTopBarMenuList.ContactUs,
    title: ContactUsConfig.texts.title,
    to: routeName.contactUs,
    isEnabled: computed(() => true),
    hamburgerMenuContext: HamburgerMenuContextLevel.DefaultContext,
  },
];

const module: Module = {
  ModuleRoutesConfig: routes,
  topMenuMenuItems,
};

export default module;
