import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { KEYS } from "@/config/firebase";
import { PoseId } from "@/config/_app-page/_pose-id";
import Http from "@/utils/http";

const _FAVORITES_POSES_DB_NAME = "favorite-poses";
const _snapshotListeners: { (): void }[] = [];
let firstInitiated = false;

function _getUser() {
  return firebase.auth().currentUser;
}

function _unsubscribeListeners() {
  if (_snapshotListeners.length) {
    _snapshotListeners.forEach((snap) => snap());
  }
}

export function sendEmailWithLoginLink(email: string) {
  const actionCodeSettings = {
    url: location.origin + "/login/email-link/confirm",
    handleCodeInApp: true,
  };

  return firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
}

export function getUserIdToken() {
  const user = _getUser();

  if (user) {
    return user.getIdToken(true);
  }

  throw new Error("403");
}

export function confirmLoginEmailLink(email: string, emailLink: string) {
  return firebase.auth().signInWithEmailLink(email, emailLink);
}

export function onUserStateChange(
  onLogin?: () => Promise<void>,
  onLogout?: () => Promise<void>,
  unsubscribe = false
): Promise<boolean> {
  return new Promise((resolve) => {
    const sub = firebase.auth().onAuthStateChanged(async (user) => {
      if (user && onLogin) {
        await onLogin();
        firstInitiated = true;
      } else if (firstInitiated && onLogout) {
        await onLogout();
        _unsubscribeListeners();
      }

      if (unsubscribe) {
        sub();
      }

      resolve(true);
    });
  });
}

export function onTokenChanged(cb: (user: firebase.auth.IdTokenResult | null) => void, once = false) {
  const sub = firebase.auth().onIdTokenChanged(async (user) => {
    user ? cb(await user.getIdTokenResult(true)) : cb(null);

    if (once) sub();
  });
}

export function logoutUser() {
  return firebase.auth().signOut();
}

export async function init() {
  firebase.initializeApp(KEYS);

  // Use emulators in localhost env
  if (process.env.VUE_APP_FIREBASE_EMULATORS_ENABLED === "true") {
    if (!process.env.VUE_APP_FIREBASE_EMULATORS_AUTH_PORT)
      throw new Error('"VUE_APP_FIREBASE_EMULATORS_AUTH_PORT" not defined');
    else if (!process.env.VUE_APP_FIREBASE_EMULATORS_FIRESTORE_PORT)
      throw new Error('"VUE_APP_FIREBASE_EMULATORS_FIRESTORE_PORT" not defined');

    firebase.auth().useEmulator(`http://localhost:${process.env.VUE_APP_FIREBASE_EMULATORS_AUTH_PORT}`);
    firebase.firestore().useEmulator("localhost", parseInt(process.env.VUE_APP_FIREBASE_EMULATORS_FIRESTORE_PORT));
  }

  await firebase.firestore().enablePersistence({ synchronizeTabs: true });

  return await onUserStateChange(undefined, undefined, true);
}

//
// Favorite poses
//
export function saveFavoritePoseId(poseId: PoseId) {
  const userUid = _getUser();

  if (userUid) {
    return firebase
      .firestore()
      .collection(_FAVORITES_POSES_DB_NAME)
      .doc(userUid.uid)
      .set({ poses: firebase.firestore.FieldValue.arrayUnion(poseId) }, { merge: true })
      .catch((e) => {
        // eslint-disable-next-line
        console.error(e);
      });
  } else {
    return Promise.reject(new Error("User not logged in."));
  }
}

export function onFavoritePoseChange(cb: CallableFunction) {
  const user = _getUser();

  if (user) {
    const snap = firebase
      .firestore()
      .collection(_FAVORITES_POSES_DB_NAME)
      .doc(user.uid)
      .onSnapshot(
        (doc) => {
          const data = doc.data();

          cb(data ? data.poses : []);
        },
        // On error
        (e) => {
          // eslint-disable-next-line
          console.error(e);
        }
      );

    _snapshotListeners.push(snap);

    return snap;
  } else {
    throw new Error("User is not logged in.");
  }
}

export async function getPremiumPosesAssets() {
  const getUrlFromBucket = (bucket: string) => `gs://${bucket}.appspot.com/premium-assets/premium-images-assets.json`;

  // ! This should be uncomment only on development and commented back before commit
  // if (process.env.NODE_ENV === "development") {
  //   const res = await import("@/assets/images/premium-images-assets.json").then((res) => {
  //     console.error(
  //       "You using development premium assets from local assets file.",
  //       "And this import should be commented before commit."
  //     );
  //     return res;
  //   });

  //   return res.default;
  // }

  return await firebase
    .storage()
    .refFromURL(getUrlFromBucket(process.env.VUE_APP_FIREBASE_PROJECT_ID as string))
    .getDownloadURL()
    .then((url) =>
      Http()
        .get<{ [index: string]: string }>(url)
        .then((res) => res.data)
    );
}

export function removeFavoritePoseId(poseId: PoseId) {
  const user = _getUser();

  if (user) {
    return firebase
      .firestore()
      .collection(_FAVORITES_POSES_DB_NAME)
      .doc(user.uid)
      .set({ poses: firebase.firestore.FieldValue.arrayRemove(poseId) }, { merge: true })
      .catch((e) => {
        // eslint-disable-next-line
        console.error(e);
      });
  } else {
    return Promise.reject(new Error("User is not logged in."));
  }
}
