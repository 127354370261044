import { createApp } from "vue";
import useAuth from "./composibles/use-auth";
import useLoading from "./composibles/use-loading";

// Service worker
import("./registerServiceWorker");

// Firebase
import { init, getUserIdToken } from "@/services/firebase";

// Global components
import { addGlobalComponents } from "./global-components/index";

// Router
import router from "./router";

// Import scss
import "./assets/styles/main.css";

// App component
import { component as AppComponent } from "./App.vue";

// Stripe
import "@stripe/stripe-js";
import useLogin from "./composibles/use-login";
import { downloadPremiumAssets } from "@/utils/assets";
import usePremium from "./composibles/use-premium";
import { onTokenRequest } from "./utils/http";

init()
  .then(async () => {
    const { registerUserStateChangeHandlers } = useLogin();
    const { onTokenChanged, updateClaims } = useAuth();
    const { stopLoadingCircle } = useLoading();
    const { activePlan } = usePremium();

    await new Promise((resolve) => {
      onTokenChanged(async (user) => {
        updateClaims(user);

        if (user) onTokenRequest(getUserIdToken);

        if (user && activePlan.enabled.value === true) await downloadPremiumAssets();

        stopLoadingCircle();
        resolve(true);
      }, false);
    });

    const app = createApp(AppComponent);

    // Import global components
    addGlobalComponents(app);

    // Add routes
    app.use(router);

    // Mount application
    app.mount("#app");

    await registerUserStateChangeHandlers();
  })
  .catch((e) => {
    // eslint-disable-next-line
    console.log(e);
  });
