
import { defineComponent, onUnmounted, watch, onMounted } from "vue";
import useApp from "@/composibles/use-app";
import useBackground from "@/composibles/use-background";
import AppContent from "./_app-view/app-content.vue";
import AppDescription from "./_app-view/app-description.vue";

export const name = "app-view";

export const component = defineComponent({
  name,
  components: { AppContent, AppDescription },
  setup() {
    const { changeContextToDefault, activeImage, onKeyUp, onKeyDown } = useApp();

    if (activeImage.value) useBackground().changeBgColorClass(activeImage.value.backgroundColor);

    watch(activeImage, (val) => {
      if (val) {
        useBackground().changeBgColorClass(val.backgroundColor);
      }
    });

    function registerKeyListeners() {
      document.addEventListener("keydown", onKeyDown);
      document.addEventListener("keyup", onKeyUp);
    }

    onMounted(registerKeyListeners);

    onUnmounted(() => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
      changeContextToDefault();
    });
  },
});

export default component;
