
import { defineComponent, ref, watch } from "vue";

// Configs
import { routeName } from "@/config/router";
import TopBarConfig from "@/config/top-bar";

// Composibles
import useTopBar from "@/composibles/top-bar/use-top-bar";

// Components
import LogoComponent from "@/components/logo.vue";
import useTailwind from "../../composibles/use-tailwind";
import useLogin from "../../composibles/use-login";
import ShareButton from "@/components/share-button.vue";
import { useRoute } from "vue-router";
import { HamburgerMenuContextLevel, TopBarMenuItem } from "@/composibles/top-bar/_use-menu";

export const name = "top-bar";
export const component = defineComponent({
  name,
  components: { Logo: LogoComponent, ShareButton },
  props: {
    whiteLogo: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    const $route = useRoute();
    const { isUserLoggedIn, logout: signOut } = useLogin();
    const { isMobile } = useTailwind();
    const { LogoColor, LogoColorState } = useTopBar(); // Logo
    const {
      landingMenuList,
      hamburgerMenuList,
      MenuType,
      openMenu,
      isMenuActive,
      closeMenu,
      activeMenuType,
    } = useTopBar(); // Menu

    async function logout() {
      await signOut();
      closeMenu();
    }

    const isLandingPage = ref($route.name === routeName.landing);

    watch(
      () => $route.name,
      (name) => {
        isLandingPage.value = name === routeName.landing;
      }
    );

    function onMenuItemClick(cbsArray: Array<CallableFunction | undefined>) {
      cbsArray.forEach((cb) => {
        if (cb) {
          cb();
        }
      });
    }

    function isHamburgerMenuContext(menuItem: TopBarMenuItem, context: HamburgerMenuContextLevel): boolean {
      return menuItem.hamburgerMenuContext == context;
    }

    return {
      HamburgerMenuContextLevel,
      isHamburgerMenuContext,
      landingMenuList,
      hamburgerMenuList,
      onMenuItemClick,

      // Routes
      loginRoute: { name: routeName.loginWithEmailLink },

      isLandingPage,
      isUserLoggedIn,
      LogoColorState,
      LogoColor,
      MenuType,
      openMenu,
      isMenuActive,
      closeMenu,
      activeMenuType,
      isMobile,
      logout,
      texts: TopBarConfig.texts,
    };
  },
});

export default component;
