
import { defineComponent } from "vue";
import useTailwind from "@/composibles/use-tailwind";

export const name = "breakpoint";

export const component = defineComponent({
  name,
  setup() {
    const { width, breakPointName } = useTailwind();

    return {
      isProduction: process.env.NODE_ENV === "production",
      breakPointName: breakPointName,
      breakPointWidth: width,
    };
  },
});

export default component;
