
import { defineComponent } from "vue";

export const name = "vs-loading";

export const component = defineComponent({
  name,
});

export default component;
