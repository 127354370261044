// eslint-disable-next-line @typescript-eslint/no-var-requires
const plugin = require("tailwindcss/plugin");

function gradientsPlugin() {
  return plugin(({ addUtilities, e, theme, variants }) => {
    const gradients = theme("gradients", {});
    const gradientVariants = variants("gradients", []);
    const utilities = Object.entries(gradients).reduce((utils, [name, [start, end]]) => {
      utils[`.bg-gradient-${e(name)}`] = {
        background: `linear-gradient(to top left, ${start}, ${end})`,
      };
      return utils;
    }, {});

    addUtilities(utilities, gradientVariants);
  });
}

// All defined colors should be defined in src/config/tailwind.ts file
const colors = {
  black: "#000000",
  white: "#ffffff",

  red: {
    100: "#ffcccc",
    200: "#ec8d88",
    300: "#e7716a",
    400: "#ff3333",
    500: "#ff0000",
    600: "#cc0000",
    700: "#990000",
    800: "#660000",
    900: "#5B142E",
  },

  gray: {
    100: "#F5F7FA",
    200: "#ECEAE9",
    300: "#D7CCC5",
    400: "#B9B9B9",
    500: "#909090",
    600: "#6F6F6F",
    700: "#525353",
    800: "#353535",
    900: "#1d1d1d",
  },

  purple: {
    100: "#ded0d5",
    200: "#bda1ab",
    300: "#9d7282",
    400: "#7c4358",
    500: "#5b142e",
    600: "#491025",
    700: "#370c1c",
    800: "#240812",
    900: "#120409",
  },

  green: {
    100: "#daf1e4",
    200: "#b6e4c9",
    300: "#91d6ae",
    400: "#6dc993",
    500: "#48bb78",
    600: "#3a9660",
    700: "#2b7048",
    800: "#1d4b30",
    900: "#0e2518",
  },

  blue: {
    100: "#e0eaeb",
    200: "#c0d4d6",
    300: "#a1bfc2",
    400: "#89b7bc",
    500: "#629499",
    600: "#4e767a",
    700: "#3b595c",
    800: "#273b3d",
    900: "#141e1f",
  },

  // poseCyan: "#89b7bc",
  // poseRed: "#E7716A",
  // poseGray: "#D7CCC5",
};

module.exports = {
  purge: false,
  theme: {
    colors,
    extend: {
      gradients: () => ({
        cyan: ["#a0c9cec4", colors.blue[400]],
        landing: ["#e49692", colors.red[300]],
        white: ["#f5f5f5", colors.white],
      }),
      fontSize: {
        "3xl": "1.75rem",
        "4xl": "2rem",
      },
      inset: {
        third: "33%",
        half: "50%",
      },
      opacity: {
        80: "0.8",
        85: "0.85",
        90: "0.90",
      },
      borderWidth: {
        3: "3px",
      },
      borderRadius: {
        xl: "0.9rem",
        "2xl": "1rem",
        "3xl": "1.1rem",
        "4xl": "1.2rem",
        "5xl": "1.3rem",
        "6xl": "1.4rem",
        "7xl": "1.5rem",
        "8xl": "1.6rem",
        "10xl": "1.7rem",
        "11xl": "1.8rem",
        "12xl": "1.9rem",
        "13xl": "2rem",
        "14xl": "2.1rem",
        "15xl": "2.2rem",
        "16xl": "2.3rem",
      },
    },
  },
  variants: {
    gradients: ["responsive", "hover"],
  },
  plugins: [gradientsPlugin()],
};
