
import { defineComponent, onMounted, ref } from "vue";
import useCategories from "@/composibles/use-categories";
import { routeName } from "@/config/router";
import usePoses from "@/composibles/use-poses";
import useBackground from "@/composibles/use-background";
import useListLayout from "@/composibles/use-list-layout";
import { Pose } from "@/config/app-page";
import useApp from "@/composibles/use-app";
import { useRoute, useRouter } from "vue-router";
import { color } from "@/config/tailwind";
import { getPoseAssetAsBase64 } from "@/utils/assets";

export const name = "category-poses-list-view";

export const component = defineComponent({
  name,
  setup() {
    const $route = useRoute();
    const $router = useRouter();
    const CategorySlug = $route.params.CategorySlug as string;
    const category = useCategories().getCategoryByTitle(CategorySlug.split("-").join(" "));

    if (!category) {
      $router.push({ name: routeName.categories });

      return { categoryTitle: "" };
    }

    const categoryPoses = ref<Pose[]>(usePoses().getPosesByCategory(category.id));

    onMounted(() => {
      useBackground().changeBgColorClass(color.white);
    });

    useListLayout().setBreadcrumbs(["Category", category.title]);

    function pushToAppPage(pose: Pose) {
      if (category) {
        useApp().changeContextToCategory(pose, category.id);
        useCategories().setActiveCategory(category.id);
      }

      $router.push({ name: routeName.app });
    }

    return { categoryTitle: category.title, categoryPoses, pushToAppPage, getPoseAssetAsBase64 };
  },
});

export default component;
