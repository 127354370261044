import { HamburgerMenuContextLevel, TopBarMenuItem } from "@/composibles/top-bar/_use-menu";
import { ModuleIndexForTopBarMenuList } from "@/config";
import { Module } from "@/types/module";
import routes from "./about-us-router";
import AboutUs from "@/config/about-us-page";
import { routeName } from "@/config/router";
import { computed } from "vue";

const topMenuMenuItems: TopBarMenuItem[] = [
  {
    menuItemIndex: ModuleIndexForTopBarMenuList.AboutUs,
    title: AboutUs.texts.title,
    to: routeName.about,
    hamburgerMenuContext: HamburgerMenuContextLevel.DefaultContext,
    isEnabled: computed(() => true),
  },
];

const module: Module = {
  ModuleRoutesConfig: routes,
  topMenuMenuItems,
};

export default module;
