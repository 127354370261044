import firebase from "firebase/app";
import { onTokenChanged } from "@/services/firebase";
import { computed, ref, readonly } from "vue";

const premiumEnabled = ref(false);
const invitedByEmail = ref(null);
const premiumPlan = ref<string | null>(null);
const invitedPartnerEmail = ref<string | null>();
const nextInvoice = ref<number | null>();
const cancelAtPeriodEnd = ref<boolean>();

function updateClaims(user: firebase.auth.IdTokenResult | null) {
  invitedByEmail.value = user?.claims.invitedByEmail || null;
  premiumEnabled.value = user?.claims.premiumEnabled || false;
  premiumPlan.value = user?.claims.premiumPlan || null;
  invitedPartnerEmail.value = user?.claims.invitedPartnerEmail ?? null;
  nextInvoice.value = user?.claims.nextInvoice ?? null;
  cancelAtPeriodEnd.value = user?.claims.cancelAtPeriodEnd ?? false;
}

export default function () {
  return {
    onTokenChanged,
    updateClaims,
    invitedByEmail: readonly(invitedByEmail),
    premiumEnabled: computed(() => premiumEnabled.value),
    premiumPlan: computed(() => premiumPlan.value),
    invitedPartnerEmail: computed(() => invitedPartnerEmail.value),
    nextInvoice: computed(() => nextInvoice.value),
    cancelAtPeriodEnd: computed(() => cancelAtPeriodEnd.value),
  };
}
