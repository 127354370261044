import { RouteRecordRaw } from "vue-router";
import { routeName } from "@/config/router";

// Components
import { component as MainLayout } from "@/layouts/main-layout/main-layout.vue";
import { component as LoginWithEmailLink } from "./views/login-with-email-link-view.vue";
import { component as LoginWithEmailLinkConfirm } from "./views/login-with-email-link-view-confirm.vue";
import useLogin from "@/composibles/use-login";
import { computed } from "vue";

const router: RouteRecordRaw[] = [
  {
    path: "/login",
    component: MainLayout,
    children: [
      {
        name: routeName.loginWithEmailLink,
        path: "email-link",
        component: LoginWithEmailLink,
        meta: {
          isValidForUser: [
            {
              valid: computed(() => useLogin().isUserLoggedIn.value === false),
              fallbackUrl: routeName.app,
            },
          ],
        },
      },
      {
        name: routeName.loginWithEmailLinkConfirm,
        path: "email-link/confirm",
        component: LoginWithEmailLinkConfirm,
        meta: {
          isValidForUser: [
            {
              valid: computed(() => useLogin().isUserLoggedIn.value === false),
              fallbackUrl: routeName.app,
            },
          ],
        },
      },
    ],
  },
];

export default router;
