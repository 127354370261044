// Routes
import { HamburgerMenuContextLevel, TopBarMenuItem } from "@/composibles/top-bar/_use-menu";
import { ModuleIndexForTopBarMenuList } from "@/config";
import { Module } from "@/types/module";
import routes from "./landing-router";
import LandingConfig from "@/config/landing-page";
import { routeName } from "@/config/router";
import { computed } from "vue";

const topMenuMenuItems: TopBarMenuItem[] = [
  {
    menuItemIndex: ModuleIndexForTopBarMenuList.Landing,
    title: LandingConfig.texts.title,
    to: routeName.landing,
    hamburgerMenuContext: HamburgerMenuContextLevel.DefaultContext,
    isEnabled: computed(() => true),
  },
];

const module: Module = {
  ModuleRoutesConfig: routes,
  topMenuMenuItems,
};

export default module;
