
import { defineComponent, PropType } from "vue";
import { routeName } from "@/config/router";
import { HiddenCategory } from "@/config/_app-page/_categories";
import { getPoseAssetAsBase64 } from "@/utils/assets";

export const name = "category-card";

export const component = defineComponent({
  name,
  props: {
    title: {
      type: String,
      required: true,
    },
    imageSrc: {
      type: String,
      required: true,
    },
    routeTo: {
      type: Object as PropType<{ name: routeName }>,
      required: true,
    },
    locked: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    return { getToPremiumRoute: { name: routeName.getPremium }, HiddenCategory, getPoseAssetAsBase64 };
  },
});

export default component;
