import { Config, ConfigTexts } from "@/types/config";

interface FavoritesPosesListConfigTexts extends ConfigTexts {
  title: string;
  noFavorites: string;
}

interface FavoritesPosesListConfig extends Config {
  texts: FavoritesPosesListConfigTexts;
}

const texts: FavoritesPosesListConfigTexts = {
  title: "Favorites",
  noFavorites: "You don't have favorites yet",
};

const config: FavoritesPosesListConfig = {
  texts,
};

export default config;
