
import { defineComponent, watch, reactive } from "vue";

export const name = "vs-button";

export const component = defineComponent({
  name,
  props: {
    text: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    btnClasses: {
      type: String,
      required: false,
      default: "",
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const buttonClass = reactive({
      "btn-disabled": props.disabled || props.loading,
    });

    watch([() => props.disabled, () => props.loading], ([newDisabled, newLoading]: boolean[]) => {
      buttonClass["btn-disabled"] = newDisabled || newLoading;
    });

    return {
      buttonClass,
      watchClick: (e: Event) => {
        if (props.loading || props.disabled) e.preventDefault();
      },
    };
  },
});

export default component;
