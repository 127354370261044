
import { defineComponent } from "vue";
import useListLayout from "@/composibles/use-list-layout";
import useBackground from "@/composibles/use-background";
import useApp from "@/composibles/use-app";
import { routeName } from "@/config/router";
import { Pose } from "@/config/app-page";
import FavoritePosesListConfig from "@/config/favorite-poses-list-page";
import { useRouter } from "vue-router";
import { color } from "@/config/tailwind";
import { getPoseAssetAsBase64 } from "@/utils/assets";

export const name = "favorites-poses-list-view";

export const component = defineComponent({
  name,
  setup() {
    const $router = useRouter();
    useListLayout().setBreadcrumbs(["Favorites"]);
    useBackground().changeBgColorClass(color.white);
    const { allFavoritesPoses } = useApp();

    function toAppPage(pose: Pose) {
      useApp().changeContextToFavorites(pose);

      $router.push({ name: routeName.app });
    }

    return {
      getPoseAssetAsBase64,
      toAppPage,
      poses: allFavoritesPoses,
      text: FavoritePosesListConfig.texts,
    };
  },
});

export default component;
