
import useTailwind from "@/composibles/use-tailwind";
import { getMainAssetAsBase64 } from "@/utils/assets";
import { PointerEventConfig, pointerEventHandler, removeEventHandler } from "@/utils/pointer-event-handler";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

export const name = "carousel-component";

export const component = defineComponent({
  name,
  setup() {
    enum ActivePlan {
      couple,
      solo,
    }

    let initPos = 0;
    let moving = false;
    let transformed = 0;
    const { width } = useTailwind();
    const track = ref<HTMLDivElement>();
    const activePlan = ref(ActivePlan.solo);
    const bubbleActiveStyle = `background-image: URL(${getMainAssetAsBase64(
      "get-premium/carousel-bubble-active.svg"
    )});`;
    const bubbleInactiveStyle = `background-image: URL(${getMainAssetAsBase64(
      "get-premium/carousel-bubble-inactive.svg"
    )});`;

    function getTransformedXValue() {
      if (track.value) {
        const transformMatrix = window.getComputedStyle(track.value).getPropertyValue("transform");

        if (transformMatrix === "none") {
          return 0;
        }

        return parseInt(transformMatrix.split(",")[4].trim());
      }

      throw new Error("Element not defined");
    }

    function activateSoloPlan() {
      if (track.value) {
        activePlan.value = ActivePlan.solo;
        track.value?.classList.add("animate");
        track.value.style.transform = `translateX(0)`;
      }
    }

    function activateCouplePlan() {
      if (track.value) {
        activePlan.value = ActivePlan.couple;
        track.value?.classList.add("animate");
        track.value.style.transform = `translateX(${width.value * -1}px)`;
      }
    }

    function pointerDown(event: Event) {
      if (event instanceof window.MouseEvent) {
        initPos = event.pageX;

        moving = true;

        transformed = getTransformedXValue();
        track.value?.classList.remove("animate");
      }
    }

    function pointerMove(event: Event) {
      if (moving && track.value && event instanceof window.MouseEvent) {
        // In 'px'
        const tolerance = 60;
        const currentPos = event.pageX;
        const diff = currentPos - initPos;
        const transform = transformed + diff;

        // Move track only in cards width plus tolerance
        if (transform >= (tolerance + width.value) * -1 && transform <= tolerance) {
          track.value.style.transform = `translateX(${transform}px)`;
        }
      }
    }

    function pointerUp(event: Event) {
      if (event instanceof window.MouseEvent) {
        const { width } = useTailwind();
        const upTransformed = getTransformedXValue();

        moving = false;

        if (activePlan.value === ActivePlan.solo && upTransformed <= (-1 * width.value) / 4) {
          activateCouplePlan();
        } else if (activePlan.value === ActivePlan.couple && upTransformed >= width.value / 4 + width.value * -1) {
          activateSoloPlan();
        } else {
          // Restores track to active plan card
          if (activePlan.value === ActivePlan.couple) {
            activateCouplePlan();
          } else {
            activateSoloPlan();
          }
        }
      }
    }

    const pointerConfig: PointerEventConfig = {
      pointerStartCb: pointerDown,
      pointerMoveCb: pointerMove,
      pointerUpCb: pointerUp,
      pointerCancelCb: () => {
        //
      },
    };

    onMounted(() => {
      if (track.value) {
        pointerEventHandler(track.value, pointerConfig, {});
      }
    });

    onUnmounted(() => {
      if (track.value) {
        removeEventHandler(track.value, pointerConfig, {});
      }
    });

    return {
      track,
      activePlan,
      ActivePlan,
      bubbleActiveStyle,
      bubbleInactiveStyle,
      activateSoloPlan,
      activateCouplePlan,
    };
  },
});

export default component;
