// eslint-disable-next-line
// @ts-ignore
import resolveConfig from "tailwindcss/resolveConfig";
// eslint-disable-next-line
// @ts-ignore
import localConfig from "@/../tailwind.config.js";
import { ref, computed } from "vue";
import { breakPointNames, color } from "@/config/tailwind";

const Tailwind = resolveConfig(localConfig);
const _innerWidth = ref(window.innerWidth);
const _innerHeight = ref(window.innerHeight);
const _breakPointName = ref<breakPointNames>();
const _mobileBreakPointNames = [breakPointNames.xs, breakPointNames.sm, breakPointNames.md];

function _getBreakPointName() {
  if (_innerWidth.value < parseInt(Tailwind.theme.screens.sm)) return breakPointNames.xs;
  else if (_innerWidth.value < parseInt(Tailwind.theme.screens.md)) return breakPointNames.sm;
  else if (_innerWidth.value < parseInt(Tailwind.theme.screens.lg)) return breakPointNames.md;
  else if (_innerWidth.value < parseInt(Tailwind.theme.screens.xl)) return breakPointNames.lg;
  else if (_innerWidth.value < parseInt(Tailwind.theme.screens["2xl"])) return breakPointNames.xl;
  else return breakPointNames["2xl"];
}

function _isMobile() {
  if (_breakPointName.value) {
    return _mobileBreakPointNames.includes(_breakPointName.value);
  }

  return false;
}

function isBreakPoint(values: breakPointNames[]) {
  if (_breakPointName.value) {
    return values.includes(_breakPointName.value);
  }

  return false;
}

function getColorValue(bgClass: color): string {
  return Tailwind.theme.colors[bgClass] || "";
}

export default function () {
  _breakPointName.value = _getBreakPointName();

  window.addEventListener("resize", () => {
    _innerWidth.value = window.innerWidth;
    _innerHeight.value = window.innerHeight;
    _breakPointName.value = _getBreakPointName();
  });

  return {
    // Getters
    isBreakPoint,
    getColorValue,

    // Vars
    width: computed(() => _innerWidth.value),
    height: computed(() => _innerHeight.value),
    breakPointName: computed(() => _breakPointName.value),
    isMobile: computed(_isMobile),
  };
}
