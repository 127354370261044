
import { defineComponent } from "vue";
import { routeName } from "@/config/router";
import useAdBlockerChecker from "@/composibles/ad-blocker-checker";
import useBackground from "@/composibles/use-background";
import PleaseDisableAdBlocker from "@/components/please-disable-ad-block.vue";
import useLogin from "./composibles/use-login";
import useApp from "./composibles/use-app";
import { useRouter } from "vue-router";
import Breakpoint from "@/components/breakpoint.vue";

export const name = "App";

export const component = defineComponent({
  name,
  components: { PleaseDisableAdBlocker, Breakpoint },
  setup() {
    const $router = useRouter();
    const { bgColorClass } = useBackground();
    const { isAdBlockerEnabled } = useAdBlockerChecker();
    const { onLogin, onLogout } = useLogin();

    const { getSavedSharePoseId, setActivePoseId, clearSavedSharePose } = useApp();

    // Watchers
    onLogin(() => {
      const poseId = getSavedSharePoseId();

      if (poseId) {
        setActivePoseId(poseId);
        clearSavedSharePose();
      }
    });

    onLogout(() => {
      $router.push({ name: routeName.landing });
    });

    return {
      isAdBlockerEnabled,
      bgColorClass,
    };
  },
});

export default component;
