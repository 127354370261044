
import { defineComponent, onMounted, computed } from "vue";
import useBackground from "@/composibles/use-background";
import useCategories from "@/composibles/use-categories";
import useListLayout from "@/composibles/use-list-layout";
import { color } from "@/config/tailwind";
import { HiddenCategory } from "@/config/_app-page/_categories";
import { routeName } from "@/config/router";
import useAuth from "@/composibles/use-auth";

// Components
import { component as CategoryCard } from "../components/category-card.vue";
import usePoses from "@/composibles/use-poses";

export const name = "categories-view";

export const component = defineComponent({
  name,
  components: { CategoryCard },
  setup() {
    const { isCategoryHasOnlyPremiumPoses, isCategoryHasAnyPoses } = usePoses();
    const { allCategories } = useCategories();
    const { premiumEnabled } = useAuth();
    const getPremiumRoute = { name: routeName.getPremium };

    onMounted(() => {
      useBackground().changeBgColorClass(color.white);
    });

    useListLayout().setBreadcrumbs(["Categories"]);

    return {
      categories: computed(() =>
        allCategories.value
          .map((cat) => ({
            locked: !premiumEnabled.value && (isCategoryHasOnlyPremiumPoses(cat.id) || !isCategoryHasAnyPoses(cat.id)),
            title: cat.title,
            routeTo: cat.toCategoryPosesListRoute,
            imageSrc: cat.thumbnailImageName,
          }))
          .sort((cat, cat2) => (cat.locked ? 1 : 0) - (cat2.locked ? 1 : 0))
      ),
      HiddenCategory,
      getPremiumRoute,
      premiumEnabled,
      isCategoryHasOnlyPremiumPoses,
      isCategoryHasAnyPoses,
    };
  },
});

export default component;
